/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import FeedFloTextInput from '../../../atoms/FeedFloTextInput';
import FeedFloDropDown from '../../../atoms/FeedFloDropDown';
import TimezoneController from './TimezoneController';
import './GeneralSettings.scss';

// data object format:
// {
//  <[key]: [value]>
//  firstName: 'John',
//  lastName: 'Doe',
//  email: 'test@email.com',
//  phoneNumber: '999 (999) 999-9999',
//  metric: true,
//  ...
// }
function GeneralSettingsView({ data = null, loading = false, onChange = () => {} }) {
  function onFormChange(key, value) {
    data = { ...data, [key]: value };
    onChange(data);
  }

  const loadingView = (
    <div className="loadingForm">
      <div className="leftCol">
        {Array(3)
          .fill(0)
          .map((r, i) => {
            return (
              <div key={`${i}leftSection`} className="leftSection">
                <div key={`${i}leftHeader`} className="header skeleton-box" />
                <div key={`${i}leftCol`} className="box skeleton-box" />
              </div>
            );
          })}
      </div>
      <div className="rightCol">
        {Array(3)
          .fill(0)
          .map((r, i) => {
            return (
              <div key={`${i}rightSection`} className="rightSection">
                <div key={`${i}rightHeader`} className="header skeleton-box" />
                <div key={`${i}rightCol`} className="box skeleton-box" />
              </div>
            );
          })}
      </div>
    </div>
  );

  return loading ? (
    loadingView
  ) : (
    <div className="GeneralSettings">
      <div className="row firstRow">
        <FeedFloTextInput
          onChange={(v) => {
            onFormChange('firstName', v);
          }}
          label="First Name"
          text={data.firstName}
        />
        <FeedFloTextInput
          onChange={(v) => {
            onFormChange('lastName', v);
          }}
          label="Last Name"
          text={data.lastName}
        />
      </div>
      <div className="row secondRow">
        {/* find a timezone library */}
        <TimezoneController
          onChange={(item) => {
            onFormChange(item.name);
          }}
          disabled
        />
        <FeedFloDropDown
          label="Metric/Imperial"
          defaultTitle="Select measurements"
          list={[
            { id: 0, name: 'Imperial', selected: !data.metric },
            { id: 1, name: 'Metric', selected: data.metric },
          ]}
          onChange={(item) => {
            onFormChange('metric', item.id === 1);
          }}
        />
      </div>
      <div className="row thirdRow">
        <FeedFloTextInput id="email" label="Email" text={data.email} disabled />
        <div>
          <label htmlFor="phoneInput">Phone Number:</label>
          <PhoneInput
            id="phoneInput"
            onChange={(_, __, ___, formattedValue) => {
              onFormChange('phoneNumber', formattedValue);
            }}
            value={data.phoneNumber}
            priority={{ ca: 0, us: 1 }}
            placeholder=""
            isValid={(inputNumber) => {
              const regex = /^(?:[0-9] ?){6,14}[0-9]$/;
              return regex.test(inputNumber);
            }}
          />
        </div>
      </div>
    </div>
  );
}

GeneralSettingsView.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default GeneralSettingsView;
