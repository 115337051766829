import React from 'react';
import PropTypes from 'prop-types';
import './FeedFloPercent.scss';

const PERCENT_RANGE_MIN = 0;
const PERCENT_RANGE_MAX = 10;

function FeedFloPercent({ number }) {
  const getColor = (number) => {
    let color = null;

    if (number > PERCENT_RANGE_MAX) {
      color = 'aboveRange';
    } else if (number <= PERCENT_RANGE_MAX && number >= PERCENT_RANGE_MIN) {
      color = 'withinRange';
    } else if (number < PERCENT_RANGE_MIN) {
      color = 'belowRange';
    }

    return color;
  };

  return <div className={`FeedFloPercent ${getColor(number)}`}>{number}%</div>;
}

FeedFloPercent.propTypes = {
  number: PropTypes.number.isRequired,
};

export default FeedFloPercent;
