import PropTypes from 'prop-types';
import './Heading.scss';

function Heading({ className = '', text = '', underline = false }) {
  const underlineClass = underline ? 'Heading--underline' : '';

  return <h1 className={`Heading ${underlineClass} ${className}`}>{text}</h1>;
}

Heading.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  underline: PropTypes.bool,
};

export default Heading;
