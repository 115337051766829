// built-in
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// external
import { gql, useQuery } from '@apollo/client';
import * as moment from 'moment';
import 'moment-duration-format';

// internal
import FeedFloTable from '../../organisms/FeedFloTable';
import StatusBadge from '../../atoms/StatusBadge';
import { FAULT_CODE_LOOKUP } from '../../utils/utils';
import useFaultCodeFilter from '../../utils/useFaultCodeFilter';

const ALERT_GQL = gql`
  query AlertQuery(
    $max_rows: Int
    $row_offset: Int
    $codeFilters: [fault_bool_exp!]!
    $faultWhereEndedAt: bigint_comparison_exp!
  ) {
    fault_aggregate(
      where: {
        _or: $codeFilters
        deleted_at: { _is_null: true }
        ended_at: $faultWhereEndedAt
        device: {
          device_assignments: {
            status: { _eq: "active" }
            deleted_at: { _is_null: true }
            ended_at: { _is_null: true }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    fault(
      order_by: { started_at: desc }
      limit: $max_rows
      offset: $row_offset
      where: {
        _or: $codeFilters
        deleted_at: { _is_null: true }
        ended_at: $faultWhereEndedAt
        device: {
          device_assignments: {
            status: { _eq: "active" }
            deleted_at: { _is_null: true }
            ended_at: { _is_null: true }
          }
        }
      }
    ) {
      device {
        device_assignments {
          feed_line {
            name
            id
            farm {
              id
              name
            }
          }
        }
      }
      duration
      started_at
      ended_at
      recently_at
      code
    }
  }
`;

function AlertTableController({ activeOnly = false }) {
  const codeFilters = useFaultCodeFilter();
  const [pageNum, setPageNum] = useState(0);
  const MAX_ROWS_TO_DISPLAY = 12;
  const { loading, data } = useQuery(ALERT_GQL, {
    variables: {
      max_rows: MAX_ROWS_TO_DISPLAY,
      row_offset: pageNum * MAX_ROWS_TO_DISPLAY,
      codeFilters,
      faultWhereEndedAt: activeOnly ? { _is_null: true } : {},
    },
  });
  const faults = data?.fault;
  const faultCount = data?.fault_aggregate?.aggregate.count;

  const tableHeaders = [
    { t: 'START TIME', s: 'm' },
    { t: 'DURATION', s: 'm' },
    { t: '', s: 'm' },
    { t: 'LOCATION', s: 'l' },
  ];

  function getTableRows() {
    if (loading) return [];

    const rows = faults.map((f) => {
      const date = moment(f.started_at * 1000).format('DD MMM, HH:mm');
      const type = FAULT_CODE_LOOKUP[f.code].name;
      const status = <StatusBadge status={f.ended_at ? 'success' : 'error'} text={type} />;

      let location = '';
      if (f.device.device_assignments[0] != null) {
        location = (
          <>
            <Link title="Go to barn" className="farmLink" to={`/b/${f.device.device_assignments[0].feed_line.farm.id}`}>
              {f.device.device_assignments[0].feed_line.farm.name}
            </Link>
            {' - '}
            <Link
              title="Go to line"
              className="lineLink"
              to={`/b/${f.device.device_assignments[0].feed_line.farm.id}/line/${f.device.device_assignments[0].feed_line.id}`}
            >
              {f.device.device_assignments[0].feed_line.name}
            </Link>{' '}
            <span>{}</span>
          </>
        );
      }

      const sDuration = (f.ended_at ? f.ended_at : new Date().getTime() / 1000) - f.started_at;
      const duration = moment.duration(sDuration * 1000).format('M [months, ] d [days, ] h [hrs, ] m [mins]');
      const alreadyFormatted = duration.indexOf(',', duration.indexOf(',') + 1) > 0;
      const reducedDuration = alreadyFormatted
        ? duration.substring(0, duration.indexOf(',', duration.indexOf(',') + 1))
        : duration;

      return [date, reducedDuration, status, location];
    });
    return rows;
  }

  return (
    <FeedFloTable
      headers={tableHeaders}
      rows={getTableRows()}
      itemTotalCount={faultCount}
      loading={loading}
      pageNum={pageNum}
      onClickNext={() => {
        setPageNum(pageNum + 1);
      }}
      onClickPrevious={() => {
        setPageNum(pageNum - 1);
      }}
      emptyImage="/images/no-alerts.svg"
    />
  );
}

AlertTableController.propTypes = {
  activeOnly: PropTypes.bool,
};

export default AlertTableController;
