import React from 'react';
import { ComingSoonIcon } from '../../atoms/Icons';
import './SystemHealthPage.scss';
import Heading from '../../atoms/Heading';

export default function SystemHealthPage() {
  return (
    <div className="SystemHealthPage">
      <Heading text="System Health" />
      <div className="placeHolder">
        <ComingSoonIcon />
      </div>
    </div>
  );
}
