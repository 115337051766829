import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import WebAppContext from '../../utils/webAppContext';
import FeedFloPercent from '../../atoms/FeedFloPercent/FeedFloPercent';
import FeedFloChart from '../../atoms/FeedFloChart';
import { weightSmallUnitLabel } from '../../utils/unitConversion';
import { addComma } from '../../utils/utils';
import './BarnConsumptionTile.scss';

function BarnConsumptionTile({ percent, usage, expected, start, end, floEvents, view }) {
  const { isMetric } = useContext(WebAppContext);
  return (
    <>
      <div className="BarnConsumptionTileView">
        <div className="left">
          <div className="title">Feed Usage</div>
          <div className="usage value">
            {addComma(usage)} <span className="unit">{weightSmallUnitLabel(isMetric)}</span>
          </div>
          <FeedFloPercent number={percent} />
        </div>
        <div className="right">
          <div className="title">Expected</div>
          <div className="expected value">
            {addComma(expected)} <span className="unit">{weightSmallUnitLabel(isMetric)}</span>
          </div>
        </div>
      </div>
      <FeedFloChart start={start} end={end} floEvents={floEvents} cumulative={view === 'cumulative'} />
    </>
  );
}

BarnConsumptionTile.propTypes = {
  percent: PropTypes.number.isRequired,
  usage: PropTypes.number.isRequired,
  expected: PropTypes.number.isRequired,
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  floEvents: PropTypes.array.isRequired,
  view: PropTypes.string.isRequired,
};

export default BarnConsumptionTile;
