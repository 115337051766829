import React from 'react';
import PropTypes from 'prop-types';
import './FeedFloToolTip.scss';

function FeedFloToolTip({ icon, description }) {
  return (
    <div className="FeedFloToolTip">
      <div className="right tooltip">
        {icon}
        <span className="noselect tooltiptext">{description}</span>
      </div>
    </div>
  );
}

FeedFloToolTip.propTypes = {
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default FeedFloToolTip;
