import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import './ListOfButtons.scss';

const pathMatch = require('path-match')({
  // path-to-regexp options
  sensitive: true,
  strict: true,
  end: true,
});

function ListOfButtons({ buttons = [], startingIndex = -1 }) {
  const [selectedIndex, setSelectedIndex] = useState(startingIndex || -1);
  const location = useLocation();

  useEffect(() => {
    let newSelected = -1;
    buttons.forEach((button, i) => {
      if (button.url === location.pathname) {
        newSelected = i;
      } else if (button?.pathMatch) {
        button.pathMatch.forEach((target) => {
          if (pathMatch(target)(location.pathname)) {
            newSelected = i;
          }
        });
      }
    });
    setSelectedIndex(newSelected);
  }, [location.pathname]);

  return (
    <ul className="buttonHolder">
      {buttons.map((button, i) => (
        <Link key={`${button.url}`} to={button.url}>
          <li className={selectedIndex === i ? 'selected noselect' : 'noselect'}>
            {button.icon}

            <div className="buttonText">{button.text}</div>
          </li>
        </Link>
      ))}
    </ul>
  );
}

ListOfButtons.propTypes = {
  buttons: PropTypes.array.isRequired,
  startingIndex: PropTypes.number,
};

export default ListOfButtons;
