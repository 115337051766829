/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useParams, Switch, Route, Redirect } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import FeedFloTabs from '../../atoms/FeedFloTabs';
import Heading from '../../atoms/Heading';
import BarnConsumptionTab from '../BarnConsumptionTab';
import BarnEquipmentTab from '../BarnEquipmentTab';
import BarnSettingsTab from '../BarnSettingsTab';
import './BarnSummaryPage.scss';

const BARN_GQL = gql`
  query BarnSummaryQuery($barn_id: uuid!) {
    farm(where: { id: { _eq: $barn_id } }) {
      name
      feed_lines(where: { deleted_at: { _is_null: true } }) {
        id
      }
    }
  }
`;

export default function BarnSummaryPage() {
  const [selectedTab, setSelectedTab] = useState(0);
  const { barnId } = useParams();
  const { loading, data } = useQuery(BARN_GQL, {
    variables: {
      barn_id: barnId,
    },
  });
  const barn = data?.farm?.[0];
  const tabs = [
    {
      title: 'Consumption',
      link: `/b/${barnId}/consumption`,
    },
    {
      title: 'Equipment',
      link: `/b/${barnId}/equipment`,
    },
    {
      title: 'Barn Settings',
      link: `/b/${barnId}/settings`,
    },
  ];

  useEffect(() => {
    let index = tabs.findIndex((tab) => tab.link === window.location.pathname);
    index = index === -1 ? 0 : index;
    setSelectedTab(index);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div className="BarnSummaryPage">
      <Heading text={barn?.name || 'loading'} />
      <FeedFloTabs tabData={tabs} defaultSelected={selectedTab} />
      <Switch>
        <Route path={`/b/${barnId}/consumption`} render={() => <BarnConsumptionTab barn={barn} barnId={barnId} />} />
        <Route path={`/b/${barnId}/equipment`} render={() => <BarnEquipmentTab barnId={barnId} />} />
        <Route path={`/b/${barnId}/settings`} render={() => <BarnSettingsTab barnId={barnId} />} />
        <Redirect from={`/b/${barnId}`} to={`/b/${barnId}/consumption`} />
      </Switch>
    </div>
  );
}
