/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RadioButton from '../../atoms/RadioButton/RadioButton';
import './FeedFloScopesList.scss';

function FeedFloScopesList({ list = [], onChange = () => {} }) {
  const [listItems, setDropDownList] = useState(list);
  if (list.length !== listItems.length) {
    setDropDownList(list);
  }

  function handleItemClicked(id) {
    const updatedList = listItems.map((l) => {
      return {
        ...l,
        selected: id === l.id,
      };
    });

    setDropDownList(updatedList);
    onChange(updatedList);
  }

  const listItemsElements = listItems.map((item) => {
    return (
      <div key={item.id} className="sideBySide">
        <div className="checkbox">
          <RadioButton
            key={`${Math.random()}row`}
            defaultChecked={item.selected}
            onChange={() => handleItemClicked(item.id)}
          />
        </div>
        <div className="title">{item.name}</div>
        <div className="description">{item.description}</div>
      </div>
    );
  });

  return (
    <div className="FeedFloScopesList">
      <div className="list">{listItemsElements}</div>
    </div>
  );
}

FeedFloScopesList.propTypes = {
  list: PropTypes.array,
  onChange: PropTypes.func,
};

export default FeedFloScopesList;
