/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './FeedFloToggleButton.scss';

function FeedFloToggleButton({ text = [], onChange = () => {} }) {
  const [toggle, setToggle] = useState(false);

  function onToggleChange(e) {
    setToggle(!toggle);
    onChange({ ...e, toggle });
  }

  return (
    <div onClick={(e) => onToggleChange(e)} className="FeedFloToggleButton">
      <div className={toggle ? 'toggle' : ''}>{text[0]}</div>
      <div className={toggle ? '' : 'toggle'}>{text[1]}</div>
    </div>
  );
}

FeedFloToggleButton.propTypes = {
  text: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

export default FeedFloToggleButton;
