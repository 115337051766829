import React, { useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';

const GET_FIRMWARE_STREAMS = gql`
  query getAllStreamsQuery {
    firmware_stream(where: { deleted_at: { _is_null: true } }) {
      name
      id
    }
  }
`;

const GET_DEVICES = gql`
  query DeviceQuery {
    device(order_by: { serial: asc }) {
      serial
      label
      id
      firmware_stream {
        id
        name
      }
    }
  }
`;

const SET_DEVICE_FW_STREAM = gql`
  mutation SetDeviceFWStream($deviceId: uuid!, $fwStreamId: uuid!) {
    update_device(where: { id: { _eq: $deviceId } }, _set: { firmware_stream_id: $fwStreamId }) {
      affected_rows
    }
  }
`;

export default function DeviceFirmwareController() {
  const { loading: deviceLoading, data: deviceData } = useQuery(GET_DEVICES);
  const devices = deviceData?.device || null;

  const { loading: fwLoading, data: fwData } = useQuery(GET_FIRMWARE_STREAMS);
  const firmwareStreams = fwData?.firmware_stream || null;

  const [setDeviceFwStream, { data: setDeviceFwData, error: setDeviceFwError }] = useMutation(SET_DEVICE_FW_STREAM);

  const SuccessUpdated = () =>
    toast.success('Successfully Updated', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const ErrorOccurred = () =>
    toast.warn('Error Occurred', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    if (setDeviceFwData) SuccessUpdated();
    if (setDeviceFwError) ErrorOccurred();
  }, [setDeviceFwData, setDeviceFwError]);

  const buildDeviceRow = (device) => {
    const list = Object.keys(firmwareStreams).map((key) => {
      const fwStream = firmwareStreams[key];
      return {
        id: fwStream.id,
        name: fwStream.name,
        selected: device.firmware_stream?.id === fwStream.id,
      };
    });

    return (
      <div className="deviceFW" key={device.id}>
        <span>
          <b>{device.label}</b> : {device.serial}
        </span>
        <FeedFloDropDown
          key={device.id}
          list={list}
          defaultTitle="None"
          onChange={(item) => {
            setDeviceFwStream({ variables: { deviceId: device.id, fwStreamId: item.id } });
          }}
        />
      </div>
    );
  };

  if (fwLoading || deviceLoading) return <>Loading</>;

  return (
    <div className="DeviceFirmwareController">
      <div>{devices.map(buildDeviceRow)}</div>
    </div>
  );
}
