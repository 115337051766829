import React from 'react';
import DeviceFirmwareController from './DeviceFirmwareController';
import FirmwareStreamController from './FirmwareStreamController';
import FirmwareUploadController from './FirmwareUploadController';
import './FirmwarePage.scss';

export default function FirmwarePage() {
  return (
    <div className="FirmwarePage">
      <h2>Firmware Streams:</h2>
      <FirmwareStreamController />
      <h3>Upload New Firmware:</h3>
      <FirmwareUploadController />
      <h2>Flo Devices:</h2>
      <DeviceFirmwareController />
    </div>
  );
}
