/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function Tab({ index = 0, title = '', link = null, selected = false, onClick = () => {} }) {
  const linkClass = link ? 'FeedFloTabs-tabLink' : '';
  const selectedClass = selected ? 'selected' : '';
  const className = `noselect ${linkClass} ${selectedClass}`;

  const onTabClick = () => {
    onClick(index);
  };

  const renderTabContent = () => {
    if (link) {
      return (
        <Link to={link} className={className} onClick={onTabClick}>
          <div>{title}</div>
        </Link>
      );
    }

    return (
      <div role="button" tabIndex={100 + index} className={className} onClick={onTabClick}>
        {title}
      </div>
    );
  };

  return (
    <>
      {renderTabContent()}
      <div className="spacer" />
    </>
  );
}

Tab.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Tab;
