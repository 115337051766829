import React from 'react';
import './Icons.scss';

export function HomeIcon() {
  return <img className="feedFloIcon" src="/icons/home.png" alt="" />;
}

export function MapIcon() {
  return <img className="feedFloIcon" src="/icons/map.png" alt="" />;
}

export function StoreIcon() {
  return <img className="feedFloIcon" src="/icons/store.png" alt="" />;
}

export function AlertIcon() {
  return <img className="feedFloIcon" src="/icons/alert.png" alt="" />;
}

export function MoneyIcon() {
  return <img className="feedFloIcon" src="/icons/money.png" alt="" />;
}

export function EKGIcon() {
  return <img className="feedFloIcon" src="/icons/ekg.png" alt="" />;
}

export function ReportIcon() {
  return <img className="feedFloIcon" src="/icons/report.png" alt="" />;
}

export function UserIcon() {
  return <img className="feedFloIcon" src="/icons/user.png" alt="" />;
}

export function GearIcon() {
  return <img className="feedFloIcon" src="/icons/gear.png" alt="" />;
}

export function BellIcon() {
  return <img className="feedFloIcon" src="/icons/bell.png" alt="" />;
}

export function EllipsisIcon() {
  return <img className="feedFloIcon" src="/icons/ellipsis.png" alt="" />;
}

export function CalendarIcon() {
  return <img className="feedFloIcon" src="/icons/calendar.png" alt="" />;
}

export function HelpIcon() {
  return <img className="feedFloIcon" src="/icons/help.png" alt="" />;
}

export function PhoneIcon() {
  return <img className="feedFloIcon" src="/icons/phone.png" alt="" />;
}

export function EmailIcon() {
  return <img className="feedFloIcon" src="/icons/email.png" alt="" />;
}

export function ChatIcon() {
  return <img className="feedFloIcon" src="/icons/chat.png" alt="" />;
}

export function InfoIcon() {
  return <img className="feedFloIcon" src="/icons/info.png" alt="" />;
}

export function FilterIcon() {
  return <img className="feedFloIcon" src="/icons/filter.png" alt="" />;
}

export function ExportIcon() {
  return <img className="feedFloIcon" src="/icons/export-arrow.png" alt="" />;
}

export function ArrowIcon() {
  return <img className="feedFloIcon" src="/icons/arrow.png" alt="" />;
}

export function MenuIcon() {
  return <img className="feedFloIcon" src="/icons/menu.png" alt="" />;
}

export function ComingSoonIcon() {
  return <img className="comingSoonIcon" src="/icons/coming-soon.png" alt="" />;
}

export function Refresh() {
  return <img className="feedFloIcon" src="/icons/refresh.png" alt="" />;
}

export function SearchIcon() {
  return <img className="feedFloIcon" src="/icons/search.png" alt="" />;
}

export function EyeIcon() {
  return <img className="feedFloIcon" src="/icons/eye.png" alt="" />;
}

export function KeyIcon() {
  return <img className="feedFloIcon" src="/icons/key.png" alt="" />;
}

export function RedXIcon() {
  return <img className="feedFloIcon" src="/icons/red_x.png" alt="" />;
}

export function CopyIcon() {
  return <img className="feedFloIcon" src="/icons/copy.png" alt="" />;
}
