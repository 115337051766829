/* eslint-disable react/jsx-no-bind */
// App.js is the only special file that gets to bypass this rule
/* eslint-disable filenames/match-regex */

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { gql, useQuery } from '@apollo/client';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import usePageTracking from './utils/usePageTracking';

import WebAppContext from './utils/webAppContext';
import LoadingView from './atoms/LoadingView';
import SideBar from './organisms/SideBar';
import TopBar from './organisms/TopBar';
import AlertPage from './pages/AlertPage';
import ApiAccessPage from './pages/APIAccessPage';
import BarnListPage from './pages/BarnListPage';
import BarnStatusPage from './pages/BarnStatusPage';
import BarnSummaryPage from './pages/BarnSummaryPage';
import DevelopersPage from './pages/DevelopersPage';
import FeedLinePage from './pages/FeedLinePage';
import MapPage from './pages/MapPage';
import NotFoundPage from './pages/NotFoundPage';
import OverviewPage from './pages/OverviewPage';
import SettingsPage from './pages/SettingsPage';
import SystemHealthPage from './pages/SystemHealth';
import ExportsPage from './pages/ExportsPage';
import FirmwarePage from './pages/FirmwarePage';
import './App.scss';

const USER_PREF_GQL = gql`
  query UserPrefQuery($user: String) {
    user_preference(where: { user_id: { _eq: $user }, version: { _eq: 0 } }) {
      preferences
    }
  }
`;

export default function App() {
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();
  const [visibleSideBar, setVisibleSideBar] = useState(false);
  const [isMetric, setIsMetric] = useState(false);

  const { loading: metricLoading, data: metricData } = useQuery(USER_PREF_GQL, {
    fetchPolicy: 'no-cache',
    variables: { user: user?.sub },
  });

  useEffect(() => {
    if (!metricLoading) {
      const pref = metricData?.user_preference?.[0]?.preferences;
      setIsMetric(pref?.metric || false);
    }
  }, [metricData]);

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect({ appState: { returnTo: window.location.pathname } });
  }

  function toggleSideBar() {
    setVisibleSideBar(!visibleSideBar);
  }
  function closeSideBar() {
    setVisibleSideBar(false);
  }
  usePageTracking();

  if (!isAuthenticated || isLoading) {
    return (
      <div className="loadingPage">
        <LoadingView />
      </div>
    );
  }

  return (
    <div className="App">
      <WebAppContext.Provider value={{ isMetric, setIsMetric }}>
        <Router>
          <SideBar open={visibleSideBar} onClickOutside={closeSideBar} />
          <div className="rightSide">
            <TopBar onMenuButton={toggleSideBar} />
            <div className="pageSpace customScroll">
              <Switch>
                <Route exact path="/" component={BarnListPage} />
                <Route path="/overview" component={OverviewPage} />
                <Route path="/settings" component={SettingsPage} />
                <Route path="/b/:barnId/line/:lineId" component={FeedLinePage} />
                <Route path="/b/:barnId" component={BarnSummaryPage} />
                <Route path="/alerts" component={AlertPage} />
                <Route path="/health" component={SystemHealthPage} />
                <Route path="/developers" component={DevelopersPage} />
                <Route path="/map" component={MapPage} />
                <Route path="/barnStatus" component={BarnStatusPage} />
                <Route path="/exports" component={ExportsPage} />
                <Route path="/firmware" component={FirmwarePage} />
                <Route path="/apiAccess" component={ApiAccessPage} />
                <Route path="*" component={NotFoundPage} />
              </Switch>
            </div>
          </div>
        </Router>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </WebAppContext.Provider>
    </div>
  );
}
