import React from 'react';
import PropTypes from 'prop-types';
import './FeedFloStatTile.scss';

function FeedFloStatTile({ title = '', value = '', subtitle = '', icon = null }) {
  return (
    <div className="FeedFloStatTitle">
      <div className="left">
        <div className="title">{title}</div>
        <div className="value">{value}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
      <div className="right tooltip">
        {icon} <span className="tooltiptext">In Development</span>
      </div>
    </div>
  );
}

FeedFloStatTile.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.node.isRequired,
};

export default FeedFloStatTile;
