import React, { useState, useRef } from 'react';
import GeneralSettingsController from './GeneralSettings/GeneralSettingsController';
import NotificationSettingsController from './NotificationSettings/NotificationSettingsController';
import Heading from '../../atoms/Heading';
import UnsavedChangesBar from '../../molecules/UnsavedChangesBar';
import './SettingsPage.scss';

function SettingsPage() {
  const [hasChanges, setHasChanges] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const generalSettingsRef = useRef();
  const notificationSettingsRef = useRef();

  const onGeneralChange = (changed) => {
    setHasChanges(changed);
  };

  const onNotificationChange = (changed) => {
    setHasChanges(changed);
  };

  const handleSaveClicked = () => {
    generalSettingsRef.current.save();
    notificationSettingsRef.current.save();
    setHasChanges(false);
  };

  return (
    <div className="SettingsPage">
      <Heading text="Settings" />
      {hasChanges && <UnsavedChangesBar isValid={isValid} onSaveClick={handleSaveClicked} />}
      <div className="row">
        <div className="subHeading">General Settings</div>
        <div className="section">
          <GeneralSettingsController ref={generalSettingsRef} onChange={(changed) => onGeneralChange(changed)} />
        </div>
      </div>
      <div className="row">
        <div className="subHeading">Notifications</div>
        <div className="section">
          <NotificationSettingsController
            onChange={(changed, valid) => {
              if (typeof valid === 'boolean') {
                setIsValid(valid);
              }
              onNotificationChange(changed);
            }}
            ref={notificationSettingsRef}
          />
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;
