import React from 'react';
import './OverviewPage.scss';

export default function OverviewPage() {
  return (
    <div className="OverviewPage">
      <img src="/OverViewMockup.png" alt="Overviewmockup" />
    </div>
  );
}
