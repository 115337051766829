import { useEffect, useState } from 'react';

import ReactGA from 'react-ga4';
import { useAuth0 } from '@auth0/auth0-react';

const GOOGLE_ANALYTICS_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

const usePageTracking = () => {
  const { user } = useAuth0();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (GOOGLE_ANALYTICS_MEASUREMENT_ID && GOOGLE_ANALYTICS_MEASUREMENT_ID.length > 1) {
      ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
    }
  }, [initialized, window.location]);

  useEffect(() => {
    if (initialized) {
      ReactGA.set({ userId: user?.sub });
      ReactGA.set({ userEmail: user?.email });
    }
  }, [user]);
};

export default usePageTracking;
