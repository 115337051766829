import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './CheckBox.scss';

function CheckBox({ defaultChecked = false, onChange = () => {} }) {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked]);

  const onCheck = (e) => {
    onChange(e.target.checked);
  };

  return <input className="CheckBox" type="checkbox" checked={isChecked} onChange={(e) => onCheck(e)} />;
}

CheckBox.propTypes = {
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default CheckBox;
