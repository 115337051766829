import React from 'react';
import BarnListController from './BarnListController';
import Heading from '../../atoms/Heading';

import './BarnListPage.scss';

function BarnListPage() {
  return (
    <div className="BarnListPage">
      <Heading text="Barns" />
      <BarnListController />
    </div>
  );
}

export default BarnListPage;
