import React, { useState } from 'react';
import FeedFloTabs from '../../atoms/FeedFloTabs';
import AlertTableController from './AlertTableController';
import Heading from '../../atoms/Heading';
import './AlertPage.scss';

export default function AlertPage() {
  const [selected, setSelected] = useState('active');
  const tabArgs = {
    tabData: [{ title: 'Active' }, { title: 'All' }],
    defaultSelected: 0,
    onClick: (i) => {
      setSelected(i === 0 ? 'active' : 'all');
    },
  };

  return (
    <div className="AlertPage">
      <Heading text="Alerts" />

      <FeedFloTabs {...tabArgs} />
      <AlertTableController activeOnly={selected === 'active'} />
    </div>
  );
}
