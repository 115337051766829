/* eslint-disable spaced-comment */
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import { useAuth0 } from '@auth0/auth0-react';
import { gql, useQuery, useMutation } from '@apollo/client';

import NotificationSettingsView from './NotificationSettings';

const GET_POLICIES_GQL = gql`
  query PerUserAlertPolicy($userID: String) {
    alert_policy(
      order_by: { farm: { name: asc } }
      where: {
        user_id: { _eq: $userID }
        farm_id: { _is_null: false }
        alert_for: { _in: [2001, 2004] }
        status: { _eq: "active" }
      }
    ) {
      alert_transport
      alert_for
      farm {
        id
        name
        organization_id
      }
      organization_id
      farm_id
      status
    }
  }
`;

const FARM_GQL = gql`
  query Farm {
    farm(order_by: { name: asc }) {
      name
      id
      organization_id
      organization {
        name
      }
    }
  }
`;

const GET_USER_PREF_GQL = gql`
  query UserPreferenceQuery {
    user_preference(where: { version: { _eq: 1001 } }) {
      preferences
    }
  }
`;

const UPSERT_ALERT_POLICY_GQL = gql`
  mutation upsert_one_alertPolicy($objects: [alert_policy_insert_input!]!) {
    insert_alert_policy(
      objects: $objects
      on_conflict: {
        constraint: farm_unique
        update_columns: [status, alert_threshold_in_seconds, renotify_interval_in_seconds]
      }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_USER_PREF_GQL = gql`
  mutation MutateUserPreference($userID: String!, $preference_data: [insert_user_preference_input!]!) {
    insert_user_preference_one(
      object: { version: 1001, user_id: $userID, preferences: { notificationTimePeriods: $preference_data } }
      on_conflict: { constraint: user_preference_user_id_version_unique, update_columns: preferences }
    ) {
      preferences
    }
  }
`;

const NotificationSettingsController = forwardRef(({ onChange }, ref) => {
  const [watchedBarnIDs, setWatchedBarnIDs] = useState(null);
  const [notificationTimePeriods, setNotificationTimeSettings] = useState(null);

  const { user } = useAuth0();

  const { loading: farmLoading, data: farmData } = useQuery(FARM_GQL, { fetchPolicy: 'no-cache' });
  const allBarns = farmData?.farm;

  const { loading: policyLoading, data: policyData } = useQuery(GET_POLICIES_GQL, {
    fetchPolicy: 'no-cache',
    variables: { userID: user.sub },
  });
  const allPolicies = policyData?.alert_policy;

  const { data: prefData } = useQuery(GET_USER_PREF_GQL, { fetchPolicy: 'no-cache' });
  const userPref = prefData?.user_preference;

  const [upsertAlertPolicy] = useMutation(UPSERT_ALERT_POLICY_GQL);

  const [updateUserPref] = useMutation(UPDATE_USER_PREF_GQL);

  useEffect(() => {
    if (!policyLoading && !farmLoading) {
      const unique = (value, index, self) => {
        return self.indexOf(value) === index;
      };
      setWatchedBarnIDs(
        allPolicies
          .map((policy) => policy.farm?.id)
          .filter((id) => id)
          .filter(unique),
      );
    }
  }, [allPolicies, allBarns]);

  useEffect(() => {
    if (userPref) {
      if (userPref[0]?.preferences?.notificationTimePeriods) {
        setNotificationTimeSettings(JSON.parse(JSON.stringify(userPref[0].preferences.notificationTimePeriods)));
      } else {
        const defaultNotificationSettings = {
          empty: {
            sms: { initial: -1, reminder: -1 },
            email: { initial: -1, reminder: -1 },
          },
          inactive: {
            sms: { initial: -1, reminder: -1 },
            email: { initial: -1, reminder: -1 },
          },
        };
        setNotificationTimeSettings(defaultNotificationSettings);
      }
    }
  }, [userPref]);

  async function mutateAlertPolicies() {
    const faultCodes = [2001, 2004];
    const codeMapping = [];
    codeMapping[2001] = 'empty';
    codeMapping[2004] = 'inactive';
    const transportTypes = ['email', 'sms'];
    const policies = [];

    allBarns.forEach((b) => {
      faultCodes.forEach((code) => {
        transportTypes.forEach((transport) => {
          const timeSettings = notificationTimePeriods[codeMapping[code]][transport];
          const enabled = watchedBarnIDs.includes(b.id) && timeSettings.initial > 0;
          policies.push({
            status: enabled ? 'active' : 'disabled',
            organization_id: null,
            farm_id: b.id,
            alert_for: code,
            alert_transport: transport,
            alert_threshold_in_seconds: timeSettings.initial,
            renotify_interval_in_seconds: timeSettings.reminder,
            user_id: user.sub,
          });
        });
      });
    });

    upsertAlertPolicy({ variables: { objects: policies } });
  }

  // eslint-disable-next-line no-unused-vars
  async function mutateUserPreference() {
    updateUserPref({
      variables: { userID: user.sub, preference_data: notificationTimePeriods },
    });
  }

  useImperativeHandle(ref, () => ({
    save: async () => {
      const savePromises = [mutateAlertPolicies(), mutateUserPreference()];
      return Promise.allSettled(savePromises);
    },
  }));

  const barnMap = allBarns?.reduce((map, b) => {
    map[b.id] = b;
    return map;
  }, {});

  return (
    notificationTimePeriods != null &&
    allBarns != null &&
    watchedBarnIDs != null && (
      <NotificationSettingsView
        notificationTimePeriods={notificationTimePeriods}
        allBarns={allBarns}
        watchedBarnIDs={watchedBarnIDs}
        onChange={({ watchedBarnIDs, notificationTimePeriods, isValid }) => {
          const sorted = watchedBarnIDs.sort((a, b) => {
            return barnMap[a].name.localeCompare(barnMap[b].name);
          });
          setWatchedBarnIDs(sorted);
          setNotificationTimeSettings(notificationTimePeriods);
          onChange(true, isValid);
        }}
      />
    )
  );
});

NotificationSettingsController.displayName = 'NotificationSettingsController';

NotificationSettingsController.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default NotificationSettingsController;
