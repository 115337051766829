const DEFAULT_ANALYSIS_SHA = '5aaedb1adc54becb759ca136769bb04ca1036dfc';

function useFeedFrameFilter(overrides = {}) {
  const defaultFilter = {
    deleted_at: { _is_null: true },
  };

  return { ...defaultFilter, ...overrides };
}

function useAnalysisFilter(overrides = {}) {
  const defaultFilter = {
    deleted_at: { _is_null: true },
    algorithm_version: { _in: [DEFAULT_ANALYSIS_SHA] },
  };

  return { ...defaultFilter, ...overrides };
}

export { useFeedFrameFilter, useAnalysisFilter, DEFAULT_ANALYSIS_SHA };
