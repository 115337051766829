import React from 'react';
import './NotFoundPage.scss';
import Heading from '../../atoms/Heading';

export default function NotFoundPage() {
  return (
    <div className="TestPage">
      <Heading text="404 - Not Found" />
      <h2>This little piggy went to the market</h2>
    </div>
  );
}
