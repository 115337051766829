import React from 'react';
import FeedFloApiTokenController from '../../organisms/FeedFloAPITokenController';
import GraphiQLView from './GraphiQlView';
import Heading from '../../atoms/Heading';

import './GraphiQlView.scss';
import './APIAccessPage.scss';

export default function APIAccessPage() {
  return (
    <div className="ApiAccessPage">
      <Heading text="API Access" />
      <FeedFloApiTokenController />
      <GraphiQLView />
    </div>
  );
}
