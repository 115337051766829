/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useContext } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import * as moment from 'moment';
import 'moment-duration-format';
import { toast } from 'react-toastify';
import WebAppContext from '../../utils/webAppContext';
import FeedFloChart from '../../atoms/FeedFloChart';
import FeedFloDatePicker from '../../molecules/FeedFloDatePicker';
import FeedFloStatTile from '../../molecules/FeedFloStatTile';
import FeedFloTable from '../../organisms/FeedFloTable';
import StatusBadge from '../../atoms/StatusBadge';
import FeedFloButton from '../../atoms/FeedFloButton';
import { EllipsisIcon, ExportIcon, FilterIcon, InfoIcon, CalendarIcon, ArrowIcon } from '../../atoms/Icons';
import { convertGramsToSmallUnits, weightSmallUnitLabel } from '../../utils/unitConversion';
import { addComma } from '../../utils/utils';
import Heading from '../../atoms/Heading';
import { useFeedFrameFilter, useAnalysisFilter } from '../../utils/useFeedFrameFilter';
import useDefaultDateRange from '../../utils/hooks/useDefaultDateRange';

import './FeedLinePage.scss';

const MAX_ROWS_LINE_SUMMARY = 50;

const FEED_LINE_GQL = gql`
  query LineQuery(
    $line_id: uuid!
    $feedFrameWhere: feed_frame_bool_exp
    $feedFrameAnalysisWhere: feed_frame_analysis_bool_exp
    $max_rows: Int
    $row_offset: Int
    $min_fault_dur: bigint = 21600
    $fault_whitelist: [Int]
  ) {
    feed_line(where: { id: { _eq: $line_id } }) {
      farm {
        name
        id
      }
      name
      device_assignments(
        where: { status: { _eq: "active" }, deleted_at: { _is_null: true }, ended_at: { _is_null: true } }
      ) {
        device {
          faults(
            where: { started_at: { _is_null: false }, ended_at: { _is_null: true }, code: { _in: $fault_whitelist } }
          ) {
            code
            started_at
            ended_at
          }
          faults_aggregate(where: { started_at: { _is_null: false }, code: { _in: $fault_whitelist } }) {
            aggregate {
              count(distinct: false)
            }
          }
        }
      }
    }
    feed_frame_aggregate(where: $feedFrameWhere) {
      aggregate {
        count
      }
    }
    feed_frame(where: $feedFrameWhere, limit: $max_rows, order_by: { started_at: desc }, offset: $row_offset) {
      feed_frame_analyses(where: $feedFrameAnalysisWhere, limit: 1, order_by: { created_at: desc_nulls_last }) {
        mass_moved_in_grams
      }
      started_at
      ended_at
    }

    chartFrames: feed_frame(where: $feedFrameWhere, order_by: { started_at: asc }) {
      feed_frame_analyses(where: $feedFrameAnalysisWhere, limit: 1, order_by: { created_at: desc_nulls_last }) {
        mass_moved_in_grams
      }
      ended_at
      started_at
    }
  }
`;

export default function FeedLinePage() {
  const { state } = useLocation();
  const { lineId, barnId } = useParams();
  const history = useHistory();
  const { isMetric } = useContext(WebAppContext);

  const inDevelopment = () =>
    toast.warn('This feature is in development', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const defaultDateRange = useDefaultDateRange(barnId);
  const initialDateRange = state?.start ? { from: moment(state?.start), to: moment(state?.end) } : defaultDateRange;

  const [pageNum, setPageNum] = useState(0);
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [lineUsage, setLineUsage] = useState(0);
  const [alertCount, setAlertCount] = useState(0);
  const [alertCountLoading, setAlertCountLoading] = useState(true);
  const [floEvents, setFloEvents] = useState([]);

  const feedFrameFilter = useFeedFrameFilter({
    started_at: { _lte: dateRange.to.unix() },
    ended_at: { _gte: dateRange.from.unix() },
    feed_line_id: { _eq: lineId },
  });
  const analysisFilter = useAnalysisFilter({ feed_frame: { feed_line_id: { _eq: lineId } } });

  const { loading, error, data } = useQuery(FEED_LINE_GQL, {
    variables: {
      feedFrameWhere: feedFrameFilter,
      feedFrameAnalysisWhere: analysisFilter,
      line_id: lineId,
      max_rows: MAX_ROWS_LINE_SUMMARY,
      row_offset: pageNum * MAX_ROWS_LINE_SUMMARY,
      min_fault_dur: 21600,
      fault_whitelist: [2001],
    },
  });

  const barnName = data?.feed_line[0].farm.name;
  const lineName = data?.feed_line[0].name;
  const feedFrameCount = data?.feed_frame_aggregate?.aggregate.count;
  let statusText = 'Loading';
  if (data?.feed_line?.[0]?.device_assignments[0]?.device)
    if (data.feed_line?.[0]?.device_assignments[0]?.device.faults.length > 0) statusText = 'Empty Pipe';
    else statusText = 'Ready';

  useEffect(() => {
    if (!loading) {
      if (error) console.log('GQL Error.');
      const numFaults = data.feed_line[0].device_assignments.reduce((sum, deviceAssignement) => {
        sum += deviceAssignement.device.faults_aggregate.aggregate.count;
        return sum;
      }, 0);
      setAlertCount(numFaults);
      setAlertCountLoading(false);
    }
  }, [data?.feed_line[0]?.device_assignments]);

  const tableHeaders = [
    { t: 'START TIME', s: 'm' },
    { t: '', s: 'm' },
    { t: 'WEIGHT', s: 'g' },
    { t: '', s: 's' },
    { t: 'RUN TIME', s: 'l' },
    { t: '', s: 's' },
  ];

  let totalUsage = 0;
  function extractFeedLineData(data) {
    const feedFrames = data.chartFrames;
    const chartEvents = feedFrames.map((r, i) => {
      const frame = feedFrames[i].feed_frame_analyses[0];
      let mass = 0;
      if (frame) {
        mass = convertGramsToSmallUnits(isMetric, frame.mass_moved_in_grams);
      }

      const event = {
        s: feedFrames[i].started_at * 1000,
        e: feedFrames[i].ended_at * 1000,
        v: mass,
      };

      totalUsage += mass;
      return event;
    });
    setLineUsage(totalUsage);
    setFloEvents(chartEvents);
  }

  useEffect(() => {
    if (!loading) extractFeedLineData(data);
  }, [data?.chartFrames]);

  function getTableRows() {
    if (loading) return [];

    const noFeedBadge = <StatusBadge status="error" />;
    const completeBadge = <StatusBadge status="success" />;
    const hamburger = (
      <div className="tooltip">
        <EllipsisIcon /> <span className="tooltiptext">In Development</span>
      </div>
    );
    const spacer = '';
    const rows = data?.feed_frame?.map((feedFrame) => {
      const date = moment(feedFrame.started_at * 1000).format('DD MMM, HH:mm');

      let weight = 'unknown';
      let status = noFeedBadge;

      if (feedFrame.feed_frame_analyses[0] !== undefined) {
        const grams = feedFrame.feed_frame_analyses[0].mass_moved_in_grams;
        weight = `${convertGramsToSmallUnits(isMetric, grams).toFixed(1)} ${weightSmallUnitLabel(isMetric)}`;
        status = completeBadge;
      }

      const sDuration = (feedFrame.ended_at ? feedFrame.ended_at : new Date().getTime() / 1000) - feedFrame.started_at;
      const duration = moment.duration(sDuration * 1000).format('h[h] m[m] s[s]');
      return [date, status, weight, spacer, duration, hamburger];
    });
    return rows;
  }

  const goToPreviousPath = () => {
    history.goBack();
  };
  function handleClickedOutside() {
    setShowDatePicker(false);
  }

  function onDateChange(e) {
    if (e.dateRange) {
      const newFrom = moment(e.dateRange.from);
      const newTo = moment(e.dateRange.to);
      setDateRange({ ...dateRange, from: newFrom, to: newTo });
    }

    setShowDatePicker(false);
  }

  const fromLabel = `${dateRange.from.format('MMM')} ${dateRange.from.format('DD')}`;
  const toLabel = `${dateRange.to.format('MMM')} ${dateRange.to.format('DD')}`;

  const dateRangeInput = (
    <>
      <div
        className="dateRangeButton"
        onClick={() => {
          setShowDatePicker(!showDatePicker);
        }}
      >
        <div className="icon">
          <CalendarIcon />
        </div>
        <div className="text">{`${fromLabel} - ${toLabel}`}</div>
      </div>
      {showDatePicker && (
        <FeedFloDatePicker
          onClickedOutside={() => handleClickedOutside()}
          onChange={(e) => onDateChange(e)}
          from={dateRange.from.toDate()}
          to={dateRange.to.toDate()}
        />
      )}
    </>
  );
  const usageTile = (
    <div className="UsageTile">
      <div className="title">Feed Usage</div>
      <div className="usage value">
        {addComma(Math.round(lineUsage))} <span className="unit">{weightSmallUnitLabel(isMetric)}</span>
      </div>
    </div>
  );
  if (error) return <>{JSON.stringify(error)}</>;
  return (
    <>
      <div className="FeedLinePage">
        <div className="topBar" type="button" onClick={goToPreviousPath}>
          <div className="icon">
            <ArrowIcon />
          </div>
          Back to Barn Summary
        </div>
        <Heading text={barnName ? `${barnName} - ${lineName}` : ''} />
        <div className="topHalf">
          <div className="topLeftSide">
            <div className="headers">{dateRangeInput}</div>
            {usageTile}
            <div className="FeedLineConsumptionHolder">
              <FeedFloChart
                floEvents={loading ? [] : floEvents}
                start={dateRange.from.unix() * 1000}
                end={dateRange.to.unix() * 1000}
                cumulative
              />
            </div>
          </div>
          <div className="topRightSide">
            <div className="statHeader">Current Group Stats</div>
            <div className="tiles">
              <FeedFloStatTile title="Feed Line Status" value={statusText} icon={<InfoIcon />} />
              <FeedFloStatTile title="Empty Pipe Count" value={alertCountLoading ? '--' : alertCount.toString()} />
              <FeedFloStatTile
                title="Total Feed Usage"
                value={addComma(Math.round(lineUsage)).toString()}
                subtitle={`Updated on ${toLabel}`}
                icon={<InfoIcon />}
              />
            </div>
          </div>
        </div>
        <div className="bottomHalf">
          <h1>Feeding Events</h1>
          <div className="bottom-header-buttons">
            <FeedFloButton onClick={inDevelopment}>
              <div className="icon">
                <FilterIcon />
              </div>
              <div className="text">Filter</div>
            </FeedFloButton>
            <FeedFloButton
              onClick={() => {
                history.push('/exports');
              }}
            >
              <div className="icon">
                <ExportIcon />
              </div>
              <div className="text">Export</div>
            </FeedFloButton>
          </div>
        </div>
        <FeedFloTable
          headers={tableHeaders}
          rows={getTableRows()}
          itemTotalCount={loading ? 0 : feedFrameCount}
          loading={loading}
          pageNum={pageNum}
          maxRows={MAX_ROWS_LINE_SUMMARY}
          onClickNext={() => {
            setPageNum(pageNum + 1);
          }}
          onClickPrevious={() => {
            setPageNum(pageNum - 1);
          }}
          emptyImage="/images/empty-table.svg"
        />
      </div>
    </>
  );
}
