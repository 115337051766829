/* eslint-disable no-bitwise */
/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line filenames/match-regex

import XLSX from 'xlsx';
import momentTZ from 'moment-timezone';

class XLSXFeedEventStrategy {
  constructor(metric) {
    this.farmMap = {};
    this.lineMap = {};
    this.massRatio = metric ? 0.001 : 0.00220462;
    const massUnit = metric ? 'kg' : 'lbs';
    const timeZoneString = momentTZ().tz(momentTZ.tz.guess()).format('Z z');

    this.sheetRows = [];
    this.sheetRows.push([
      `Farm Name`,
      `Line Name`,
      `Start ${timeZoneString}`,
      `End ${timeZoneString}`,
      `Mass Monitored (${massUnit})`,
    ]);
  }

  setFarmObjects = (farms) => {
    farms.forEach((f) => {
      this.farmMap[f.id] = f.name;
      f.feed_lines.forEach((l) => {
        this.lineMap[l.id] = l.name;
      });
    });
  };

  processFeedFramePage = (farmId, lineId, dataArray) => {
    const farmName = this.farmMap[farmId];
    const lineName = this.lineMap[lineId];
    dataArray.forEach((d) => {
      this.sheetRows.push([farmName, lineName, new Date(d.s * 1000), new Date(d.e * 1000), d.mass * this.massRatio]);
    });
  };

  createFile = () => {
    const s2ab = (s) => {
      if (typeof ArrayBuffer !== 'undefined') {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      const buf = new Array(s.length);
      for (let i = 0; i !== s.length; i += 1) buf[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };

    const workbook = XLSX.utils.book_new();
    const sheetData = XLSX.utils.aoa_to_sheet(this.sheetRows);
    XLSX.utils.book_append_sheet(workbook, sheetData, 'Sheet 1');

    const wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' };
    const wbout = XLSX.write(workbook, wopts);
    return new Blob([s2ab(wbout)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  };
}
export default XLSXFeedEventStrategy;
