/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line filenames/match-regex

import moment from 'moment';
import momentTZ from 'moment-timezone';

class CSVFeedEventStrategy {
  constructor(metric) {
    this.csvLines = [];
    const massUnit = metric ? 'kg' : 'lbs';
    const timeZoneString = momentTZ().tz(momentTZ.tz.guess()).format('Z z');
    this.csvLines.push(
      // eslint-disable-next-line max-len
      `Farm Name, Line Name, Start ${timeZoneString} YYYY/MM/DD HH:mm:ss , End ${timeZoneString} YYYY/MM/DD HH:mm:ss, Start Epoch(s), End Epoch(s),  Mass Monitored (${massUnit})\n`,
    );
    this.farmMap = {};
    this.lineMap = {};
    this.massRatio = metric ? 0.001 : 0.00220462;
  }

  setFarmObjects = (farms) => {
    farms.forEach((f) => {
      this.farmMap[f.id] = f.name;
      f.feed_lines.forEach((l) => {
        this.lineMap[l.id] = l.name;
      });
    });
  };

  processFeedFramePage = (farmId, lineId, dataArray) => {
    const farmName = this.farmMap[farmId];
    const lineName = this.lineMap[lineId];
    const momentFormatStr = 'YYYY/MM/DD HH:mm:ss';
    dataArray.forEach((d) => {
      this.csvLines.push(
        `${farmName},${lineName},${moment(d.s * 1000).format(momentFormatStr)},${moment(d.e * 1000).format(
          momentFormatStr,
        )},${d.s},${d.e},${(d.mass * this.massRatio).toFixed(2)}\n`,
      );
    });
  };

  createFile = () => {
    const toWrite = this.csvLines;
    this.csvLines = null;
    return new Blob(toWrite, { type: 'text/csv' });
  };
}
export default CSVFeedEventStrategy;
