/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import './FeedFloButton.scss';

/**
 * FeedFloButton
 *
 * type: 'primary' (default) or 'secondary'
 *     primary: black text (#3c4257) on white background (#fff)
 *     secondary: white text (#fff) on green background (#27ae60)
 */
function FeedFloButton({ children = [], type = 'primary', disabled = false, onClick = () => {} }) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={onClick} className={`noselect FeedFloButton ${disabled ? 'disabled' : 'enabled'} ${type || ''}`}>
      {children}
    </div>
  );
}

FeedFloButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export default FeedFloButton;
