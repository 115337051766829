/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import WebAppContext from '../../utils/webAppContext';
import FeedFloChart from '../../atoms/FeedFloChart/FeedFloChart';
import { weightSmallUnitLabel } from '../../utils/unitConversion';
import { addComma } from '../../utils/utils';
import StatusBadge from '../../atoms/StatusBadge/StatusBadge';
import './FeedLineConsumptionTile.scss';

function FeedLineConsumptionTile({
  lineName = '',
  lineId = '',
  usage = 0,
  start = 0,
  end = 0,
  floEvents = [],
  view = 'cumulative',
  faultText = '',
  barnId = '',
}) {
  const { isMetric } = useContext(WebAppContext);
  return (
    <>
      <div className="FeedLineConsumptionTile">
        <div className="left">
          <div className="topLeft">
            <div className="line">
              <div className="name">{lineName}</div> {faultText && <StatusBadge status="error" text={faultText} />}
            </div>
          </div>
          <div className="usage">
            {addComma(usage)} <span className="unit">{weightSmallUnitLabel(isMetric)}</span>
          </div>
        </div>
        <div className="right">
          <Link
            to={{
              pathname: `/b/${barnId}/line/${lineId}`,
              state: { start, end },
            }}
            className="link"
          >
            View
          </Link>
        </div>
      </div>
      <FeedFloChart start={start} end={end} floEvents={floEvents} cumulative={view === 'cumulative'} />
    </>
  );
}

FeedLineConsumptionTile.propTypes = {
  lineName: PropTypes.string.isRequired,
  lineId: PropTypes.string.isRequired,
  barnId: PropTypes.string.isRequired,
  usage: PropTypes.number.isRequired,
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  floEvents: PropTypes.array.isRequired,
  view: PropTypes.string,
  faultText: PropTypes.string,
};

export default FeedLineConsumptionTile;
