/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line filenames/match-regex

import { gql } from '@apollo/client';
import { FAULT_CODE_LOOKUP, ALL_FAULT_CODE_NUMBERS } from '../utils';

const REQUEST_LIMIT = 500;

class Factory {
  constructor({ farmIds, startTime, stopTime, gqlClient, strategy, progressCallback }) {
    this.strategy = strategy;
    this.progressCallback = progressCallback;
    this.farmIds = farmIds;
    this.farmData = [];
    this.eventData = [];
    this.startTime = startTime;
    this.stopTime = stopTime;
    this.gqlClient = gqlClient;
  }

  async start() {
    this.progressCallback(0);

    this.farmData = await this.getFarmData(this.farmIds);
    if (this.strategy.processFeedFramePage) await this.downloadFeedFrames();
    if (this.strategy.processFaultCodes) await this.downloadFaultCodes();
  }

  async downloadFaultCodes() {
    let downloadedDataPoints = 0;

    const totalNumberOfDataPoints = await this.getNumFaultCodes();
    this.strategy.setFarmObjects(this.farmData);

    for (const farm of this.farmData) {
      for (const line of farm.feed_lines) {
        let currEventData = null;
        let offset = 0;
        do {
          // eslint-disable-next-line no-await-in-loop
          currEventData = await this.getPageOfFaultCodesForLine(offset, REQUEST_LIMIT, line.id);
          this.strategy.processFaultCodes(farm.id, line.id, currEventData);
          offset += currEventData.length;
          downloadedDataPoints += currEventData.length;
          this.progressCallback(downloadedDataPoints / totalNumberOfDataPoints);
        } while (currEventData.length >= REQUEST_LIMIT);
      }
    }
  }

  async downloadFeedFrames() {
    let downloadedDataPoints = 0;

    const totalNumberOfDataPoints = await this.getNumFeedEvents();
    this.strategy.setFarmObjects(this.farmData);

    for (const farm of this.farmData) {
      for (const line of farm.feed_lines) {
        let currEventData = null;
        let offset = 0;
        do {
          // eslint-disable-next-line no-await-in-loop
          currEventData = await this.getPageOfFeedFrameDataForLine(offset, REQUEST_LIMIT, line.id);
          const filteredEventData = currEventData.filter((frame) => {
            return frame.mass;
          });
          this.strategy.processFeedFramePage(farm.id, line.id, filteredEventData);
          offset += currEventData.length;
          downloadedDataPoints += currEventData.length;
          this.progressCallback(downloadedDataPoints / totalNumberOfDataPoints);
        } while (currEventData.length >= REQUEST_LIMIT);
      }
    }
  }

  async getNumFeedEvents() {
    const lineReducer = (arr, farm) => arr.concat(farm.feed_lines);
    const idReducer = (arr, line) => arr.concat(line.id);
    const allLineIds = this.farmData.reduce(lineReducer, []).reduce(idReducer, []);

    const query = gql`
      query MyQuery {
        feed_frame_aggregate(where: {
          feed_line_id: { _in: ${JSON.stringify(allLineIds)} },
          started_at:{_lte:${Math.round(this.stopTime.getTime() / 1000)}},
          ended_at:{_gte:${Math.round(this.startTime.getTime() / 1000)}},
          deleted_at:{ _is_null: true },
          release_level: { _eq: 0 }
        }) {
          aggregate {
            count
          }
        }
      }
    `;

    return this.gqlClient.query({ query }).then((data) => {
      return data.data.feed_frame_aggregate.aggregate.count;
    });
  }

  async getNumFaultCodes() {
    const lineReducer = (arr, farm) => arr.concat(farm.feed_lines);
    const idReducer = (arr, line) => arr.concat(line.id);
    const allLineIds = this.farmData.reduce(lineReducer, []).reduce(idReducer, []);

    const query = gql`
      query MyQuery {
        fault_aggregate(where: {
            started_at:{_lte:${Math.round(this.stopTime.getTime() / 1000)}},
            _or: [{ ended_at: {_gte: ${Math.round(this.startTime.getTime() / 1000)}} },
                  { ended_at: { _is_null: true } }],
            deleted_at:{ _is_null: true },
            device: {device_assignments: {feed_line_id: { _in: ${JSON.stringify(allLineIds)} }}},
            code: { _in: ${JSON.stringify(ALL_FAULT_CODE_NUMBERS)} }
        }) {
          aggregate {
            count
          }
        }
      }
    `;

    return this.gqlClient.query({ query }).then((data) => {
      return data.data.fault_aggregate.aggregate.count;
    });
  }

  createFile() {
    return this.strategy.createFile();
  }

  async getPageOfFeedFrameDataForLine(offset, limit, lineID) {
    const query = gql`
      query FeedFrameExport {
        feed_frame(
          limit: ${limit},
          offset: ${offset},
          order_by: { started_at: asc },
          where: {
            started_at:{_lte:${Math.round(this.stopTime.getTime() / 1000)}},
            ended_at:{_gte:${Math.round(this.startTime.getTime() / 1000)}},
            deleted_at:{ _is_null: true },
            feed_line_id: { _eq: "${lineID}" },
            release_level: { _eq: 0 }
          }) {
          feed_frame_analyses {
            mass_moved_in_grams
          }
          ended_at
          started_at
        }
      }
    `;
    // eslint-disable-next-line no-await-in-loop
    return this.gqlClient.query({ query }).then((data) => {
      return data.data.feed_frame.map((frame) => {
        return {
          s: frame.started_at,
          e: frame.ended_at,
          mass: frame.feed_frame_analyses[0]?.mass_moved_in_grams,
        };
      });
    });
  }

  async getPageOfFaultCodesForLine(offset, limit, lineID) {
    const query = gql`
      query FaultCodeExport {
        fault(
          limit: ${limit},
          offset: ${offset},
          order_by: { started_at: asc },
          where: {
            started_at:{_lte:${Math.round(this.stopTime.getTime() / 1000)}},
             _or: [{ ended_at: {_gte:${Math.round(this.startTime.getTime() / 1000)}} },
                  { ended_at: { _is_null: true } }],
            deleted_at:{ _is_null: true },
            device: {device_assignments: {feed_line_id: { _eq: "${lineID}" }}},
            code: { _in: ${JSON.stringify(ALL_FAULT_CODE_NUMBERS)} }
          }) {
          code
          ended_at
          started_at
        }
      }
    `;
    // eslint-disable-next-line no-await-in-loop
    return this.gqlClient.query({ query }).then((data) => {
      return data.data.fault.map((f) => {
        return {
          s: f.started_at,
          e: f?.ended_at,
          faultText: FAULT_CODE_LOOKUP[f.code].name,
        };
      });
    });
  }

  getFarmData(farmIds) {
    return this.gqlClient
      .query({
        query: gql`
      query FarmDataQuery {
        farm(where: { id: { _in: ${JSON.stringify(farmIds)} } }, order_by: { name: asc }) {
          id
          name
          feed_lines {
            name
            id
          }
        }
      }
    `,
      })
      .then((response) => {
        return response.data.farm;
      });
  }
}

export default Factory;
