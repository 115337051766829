import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RadioButton from '../../atoms/RadioButton';
import FeedFloNumInput from '../../atoms/FeedFloNumInput';

function FeedFloNotificationTimeSetting({
  title = 'Default Title',
  usingOff = true,
  defaultSet = 3 * 3600,
  offIsSet = -1,
  customTime = 3 * 3600,
  disabled = false,
  onChange = () => {},
}) {
  const [isOff, setisOff] = useState(usingOff);
  const [timePeriod, setTimePeriod] = useState(isOff ? offIsSet : customTime);

  const hours = Math.floor(timePeriod / 3600);
  const minutes = Math.floor((timePeriod % 3600) / 60);

  const validInput = timePeriod >= 20 * 60 || timePeriod === offIsSet;

  const setNewTime = (h, m) => {
    const newTime = h * 3600 + m * 60;
    setTimePeriod(newTime);
  };

  useEffect(() => {
    onChange(timePeriod);
  }, [timePeriod]);

  useEffect(() => {
    if (disabled) {
      setisOff(true);
      setTimePeriod(offIsSet);
    }
  }, [disabled]);

  return (
    <div className="dropHeader selectAll">
      <div className={`${disabled ? 'greyTitle' : ''} align`}>{title}</div>
      <div className={`radioButton ${disabled ? 'default-text' : ''}`}>
        <RadioButton
          key={`${Math.random()}row`}
          defaultChecked={isOff}
          onChange={() => {
            setisOff(true);
            setTimePeriod(offIsSet);
          }}
          disabled={disabled}
        />
        Off
      </div>
      <div className={`radioButton ${disabled ? 'default-text' : ''}`}>
        <RadioButton
          key={`${Math.random()}row`}
          defaultChecked={!isOff}
          onChange={() => {
            setisOff(false);
            setTimePeriod(defaultSet);
          }}
          disabled={disabled}
        />
        Set <div className="default-text alignSetText">{`(${Math.floor(defaultSet / (60 * 60))} hours)`}</div>
      </div>
      <div className="radioButton">
        <div
          className={`textInput align ${isOff ? 'doNotDisplay' : ''} ${!validInput ? 'error' : ''} ${
            disabled ? 'default-text' : ''
          }`}
        >
          <FeedFloNumInput
            value={`${hours}`}
            onChange={(inputHours) => {
              setNewTime(inputHours, minutes);
            }}
          />
          Hours
        </div>
        <div
          className={`textInput ${isOff ? 'doNotDisplay' : ''} ${!validInput ? 'error' : ''} ${
            disabled ? 'default-text' : ''
          }`}
        >
          <FeedFloNumInput
            value={`${minutes}`}
            onChange={(inputMins) => {
              setNewTime(hours, inputMins);
            }}
          />
          Min.
        </div>
      </div>
      {!validInput && <div className="align errorText">Minimum 20 minutes</div>}
    </div>
  );
}

FeedFloNotificationTimeSetting.propTypes = {
  title: PropTypes.string,
  usingOff: PropTypes.bool,
  defaultSet: PropTypes.number,
  offIsSet: PropTypes.number,
  customTime: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FeedFloNotificationTimeSetting;
