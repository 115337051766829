/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import 'react-phone-input-2/lib/plain.css';
import './FeedFloTextInput.scss';

function FeedFloTextInput({
  label = '',
  disabled = false,
  text = '',
  icon = null,
  defaultText = '',
  onChange = () => {},
}) {
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    setInputText(text);
  }, [text]);

  function onTextInputChange(e) {
    onChange(e.target.value);
  }
  const id = uniqid();
  function getInputElement() {
    return (
      <input
        id={id}
        onChange={(e) => {
          setInputText(e.target.value);
          onTextInputChange(e);
        }}
        className={`textBox ${icon !== undefined ? 'moveText' : ''} ${disabled ? 'disabled' : ''}`}
        type="text"
        placeholder={defaultText}
        value={inputText}
        disabled={disabled}
      />
    );
  }

  return (
    <div className="FeedFloTextInput">
      <label className={`${disabled ? 'disabled' : ''}`} htmlFor={id}>
        {label}
      </label>
      <div className="input-icons ">
        <i className="user icon">{icon}</i>
        {getInputElement()}
      </div>
    </div>
  );
}

FeedFloTextInput.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  defaultText: PropTypes.string,
  onChange: PropTypes.func,
  icon: PropTypes.element,
};

export default FeedFloTextInput;
