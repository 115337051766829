/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import { EyeIcon, SearchIcon } from '../../atoms/Icons';
import 'react-phone-input-2/lib/plain.css';
import './FeedFloSearchBar.scss';

function FeedFloSearchBar({ items = [], onChange = () => {} }) {
  const [inputText, setInputText] = useState('');

  return (
    <div className="FeedFloSearchBar">
      <div className="input-icons">
        <i className="user icon">
          <SearchIcon />
        </i>
        <Autocomplete
          className="autoComplete"
          wrapperStyle={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '4px',
            fontSize: '1rem',
            width: '100%',
          }}
          menuStyle={{}} // casey when you see this, where you can change position value
          getItemValue={(item) => item.label}
          items={items}
          shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
          renderItem={(item, isHighlighted) => (
            <div
              key={item.label}
              className="input-field"
              style={{ position: 'relative', background: isHighlighted ? 'lightgray' : 'white' }}
            >
              {item.label}
              <div>
                <EyeIcon />
              </div>
            </div>
          )}
          value={inputText}
          onChange={(e) => {
            setInputText(e.target.value);
          }}
          onSelect={(val, item) => {
            onChange(item.id);
            setInputText('');
          }}
        />
      </div>
    </div>
  );
}

FeedFloSearchBar.propTypes = {
  onChange: PropTypes.func,
  items: PropTypes.array,
};

export default FeedFloSearchBar;
