/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ArrowIcon } from '../Icons';
import './FeedFloDropDown.scss';

function FeedFloDropDown({ label = null, list = [], defaultTitle = '', disabled = false, onChange = () => {} }) {
  const [dropDownList, setDropDownList] = useState(list);
  const [currSelected, setCurrSelected] = useState(null);
  const [toggleItem, setToggleItem] = useState(false);
  const dropRef = useRef(null);

  if (list.length !== dropDownList.length) {
    setDropDownList(list);
  }

  const dropDownItemMap = {};
  dropDownList.forEach((item) => {
    dropDownItemMap[item.id] = item;
  });

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (dropRef.current && !dropRef.current.contains(event.target)) {
        setToggleItem(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropRef]);

  useEffect(() => {
    list.forEach((item) => {
      if (item.selected) setCurrSelected(item.id);
    });
    setDropDownList(list);
  }, [list]);

  function resetThenSet(clickedId) {
    const updatedList = list.map((l) => {
      return {
        id: l.id,
        name: l.name,
        selected: clickedId === l.id,
      };
    });

    setDropDownList(updatedList);
  }

  function handleItemClicked(item) {
    resetThenSet(item.id);
    setCurrSelected(item.id);
    onChange(item);
  }
  function handleHeaderClicked() {
    if (!disabled) {
      setToggleItem(!toggleItem);
    }
  }

  const listItem = dropDownList.map((item) => {
    return (
      <div
        key={item.id}
        onClick={() => handleItemClicked(item)}
        className={`listItem ${item.selected ? 'selected' : ''}`}
      >
        {item.name}
      </div>
    );
  });
  return (
    <div ref={dropRef} className="DropDown noselect">
      <div className={`${disabled || list.length === 0 ? 'disabled' : ''}`} onClick={() => handleHeaderClicked()}>
        {label && <label className={`${disabled || list.length === 0 ? 'disabled' : ''}`}>{label}</label>}
        <div className={`dropDownHeader ${disabled || list.length === 0 ? 'disabled' : ''}`}>
          <div className={`selected ${disabled || list.length === 0 ? 'disabled' : ''}`}>
            {dropDownItemMap[currSelected]?.name || defaultTitle}
          </div>
          <div className={`icon ${toggleItem ? 'showList' : ''} ${disabled || list.length === 0 ? 'disabled' : ''}`}>
            <ArrowIcon />
          </div>
        </div>
        {toggleItem && <div className={`list ${label ? 'with-label' : ''}`}>{listItem}</div>}
      </div>
    </div>
  );
}

FeedFloDropDown.propTypes = {
  label: PropTypes.string,
  list: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  defaultTitle: PropTypes.string,
};

export default FeedFloDropDown;
