import { useMemo } from 'react';

export default function useFaultCodeFilter() {
  const now = useMemo(() => new Date(), []);
  if (!now) return [];
  return [
    {
      code: { _eq: 2001 },
      started_at: { _lt: Math.floor(now?.getTime() / 1000) - 20 * 60 },
      _or: [{ ended_at: { _is_null: true } }, { duration: { _gt: 20 * 60 } }],
    },
    {
      code: { _eq: 2004 },
      started_at: { _lt: Math.floor(now?.getTime() / 1000) - 12 * 60 * 60 },
      _or: [{ ended_at: { _is_null: true } }, { duration: { _gt: 12 * 60 * 60 } }],
    },
  ];
}
