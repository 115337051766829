export const addComma = (num) => {
  if (num === undefined) return '';
  return num.toLocaleString();
};

const FAULT_CODE_DATA = [
  { num: 2004, name: 'Inactive Auger' },
  { num: 2001, name: 'Empty Pipe' },
];

// eslint-disable-next-line no-sequences
export const FAULT_CODE_LOOKUP = FAULT_CODE_DATA.reduce((dict, f) => ((dict[f.num] = f), dict), {});

export const ALL_FAULT_CODE_NUMBERS = FAULT_CODE_DATA.map((f) => f.num);

export default [addComma, ALL_FAULT_CODE_NUMBERS, FAULT_CODE_LOOKUP];
