/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import './FeedFloTabs.scss';

function FeedFloTabs({ tabData = [], defaultSelected = 0, onClick = () => {} }) {
  const [currSelectedIndex, setCurrSelectedIndex] = useState(defaultSelected);

  const onTabClick = (i) => {
    setCurrSelectedIndex(i);
    onClick(i);
  };

  return (
    <div className="FeedFloTabs">
      {tabData.map((tab, i) => {
        return (
          <Tab
            key={i}
            index={i}
            title={tab.title}
            link={tab.link}
            selected={currSelectedIndex === i}
            onClick={onTabClick}
          />
        );
      })}
      <div className="grower" />
    </div>
  );
}

FeedFloTabs.propTypes = {
  tabData: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  defaultSelected: PropTypes.number,
};

export default FeedFloTabs;
