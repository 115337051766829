import React, { useState, useEffect } from 'react';
import GraphiQL from 'graphiql';
import GraphiQLExplorer from 'graphiql-explorer';
import { buildClientSchema, getIntrospectionQuery } from 'graphql';
import { KeyIcon } from 'atoms/Icons';
import FeedFloTextInput from 'atoms/FeedFloTextInput';
import StatusBadge from 'atoms/StatusBadge';
import { makeDefaultArg, getDefaultScalarArgValue } from './customArgs';
import 'graphiql/graphiql.css';
import './GraphiQlView.scss';

const DEFAULT_QUERY = `# shift-option/alt-click on a query below to jump to it in the explorer
# option/alt-click on a field in the explorer to select all subfields
query allFarmsAndAllFeedEventsQuery {
  farm {
    name
    feed_lines {
      feed_frames(limit: 100, order_by: {started_at: desc}) {
        mass_grams
        started_at
        ended_at
      }
    }
  }
}`;

export default function GraphiQLView() {
  let graphIQlQuery = null;
  const [schema, setSchema] = useState(null);
  const [apiKey, setApiKey] = useState('');
  const [query, setQuery] = useState(DEFAULT_QUERY);
  const [explorerIsOpen, setExplorerIsOpen] = useState(true);
  const [hasValidToken, setHasValidToken] = useState(false);
  const [currentTokenState, setCurrentTokenState] = useState(false);

  // const handleInspectOperation = (cm, mousePos) => {
  //   const parsedQuery = parse(query || '');

  //   if (!parsedQuery) {
  //     console.error("Couldn't parse query document");
  //     return null;
  //   }

  //   const token = cm.getTokenAt(mousePos);
  //   const start = { line: mousePos.line, ch: token.start };
  //   const end = { line: mousePos.line, ch: token.end };
  //   const relevantMousePos = {
  //     start: cm.indexFromPos(start),
  //     end: cm.indexFromPos(end),
  //   };

  //   const position = relevantMousePos;

  //   const def = parsedQuery.definitions.find((definition) => {
  //     if (!definition.loc) {
  //       console.log('Missing location information for definition');
  //       return false;
  //     }

  //     const { start, end } = definition.loc;
  //     return start <= position.start && end >= position.end;
  //   });

  //   if (!def) {
  //     console.error('Unable to find definition corresponding to mouse position');
  //     return null;
  //   }

  //   let operationKind = 'unknown';
  //   if (def.kind === 'OperationDefinition') {
  //     operationKind = def.operation;
  //   } else if (def.kind === 'FragmentDefinition') {
  //     operationKind = 'fragment';
  //   }

  //   let operationName = 'unknown';
  //   if (def.kind === 'OperationDefinition' && !!def.name) {
  //     operationName = def.name.value;
  //   } else if (def.kind === 'FragmentDefinition' && !!def.name) {
  //     operationName = def.name.value;
  //   }

  //   const selector = `.graphiql-explorer-root #${operationKind}-${operationName}`;

  //   const el = document.querySelector(selector);
  //   return el && el.scrollIntoView();
  // };

  async function fetcher(params) {
    return fetch('https://api.feedflo.com/v1/graphql/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        return response.text();
      })
      .then(async (responseBody) => {
        try {
          return JSON.parse(responseBody);
        } catch (e) {
          return responseBody;
        }
      });
  }

  useEffect(() => {
    fetcher({
      query: getIntrospectionQuery(),
    })
      .then((result) => {
        // const editor = graphIQlQuery?.getQueryEditor();
        // editor.setOption('extraKeys', {
        //   ...(editor.options.extraKeys || {}),
        //   'Shift-Alt-LeftClick': handleInspectOperation,
        // });
        setHasValidToken(true);
        setCurrentTokenState('Valid Token');
        setSchema(buildClientSchema(result.data));
      })
      .catch((error) => {
        setHasValidToken(false);
        setCurrentTokenState('Token Invalid, Expired, or Revoked');
        console.log(error);
      });
  }, [apiKey]);

  const handleEditQuery = (query) => setQuery(query);

  const handleToggleExplorer = () => {
    setExplorerIsOpen(!explorerIsOpen);
  };

  return (
    <div className="feedflo-graphiql-container">
      <div className="topExplorerLine">
        <h2>API Tester</h2>
        <div className="tokenStatusHolder">
          <StatusBadge text={currentTokenState} status={hasValidToken ? 'success' : 'error'} />
        </div>
        <FeedFloTextInput defaultText="Your key here" icon={<KeyIcon />} onChange={(result) => setApiKey(result)} />
      </div>
      <div className="url">https://api.feedflo.com/v1/graphql/</div>
      {/* <div className="url">authorization : Bearer {apiKey}</div> */}

      <div className="graphiql-container">
        <GraphiQLExplorer
          schema={schema}
          query={query}
          onEdit={handleEditQuery}
          onRunOperation={(operationName) => graphIQlQuery.handleRunQuery(operationName)}
          explorerIsOpen={explorerIsOpen}
          onToggleExplorer={handleToggleExplorer}
          getDefaultScalarArgValue={getDefaultScalarArgValue}
          makeDefaultArg={makeDefaultArg}
        />
        <GraphiQL
          ref={(ref) => {
            graphIQlQuery = ref;
          }}
          fetcher={(params) => {
            return fetcher(params);
          }}
          schema={schema}
          query={query}
          onEditQuery={handleEditQuery}
        >
          <GraphiQL.Toolbar>
            <GraphiQL.Button
              onClick={() => graphIQlQuery.handlePrettifyQuery()}
              label="Prettify"
              title="Prettify Query (Shift-Ctrl-P)"
            />
            <GraphiQL.Button onClick={() => graphIQlQuery.handleToggleHistory()} label="History" title="Show History" />
            <GraphiQL.Button onClick={handleToggleExplorer} label="Explorer" title="Toggle Explorer" />
          </GraphiQL.Toolbar>
        </GraphiQL>
      </div>
    </div>
  );
}
