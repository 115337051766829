import { useContext } from 'react';
import PropTypes from 'prop-types';
import WebAppContext from '../../utils/webAppContext';
import { convertGramsToLargeUnits, weightLargeUnitLabel } from '../../utils/unitConversion';
import LoadingSkeleton from '../LoadingSkeleton';
import './BinSymbol.scss';

function BinSymbol({ loading = false, name = '', capacity = 0 }) {
  const { isMetric } = useContext(WebAppContext);

  const renderCapacityLabel = () => {
    const largeUnits = convertGramsToLargeUnits(isMetric, capacity);
    const unitLabel = weightLargeUnitLabel(isMetric).toLowerCase();

    if (largeUnits === 1) {
      return `${largeUnits} ${unitLabel}`;
    }

    return `${largeUnits} ${unitLabel}s`;
  };

  if (loading) {
    return (
      <div className="BinSymbol">
        <LoadingSkeleton className="BinSymbol-loader BinSymbol-loader--small BinSymbol-loader--tall" />
        <LoadingSkeleton className="BinSymbol-loader BinSymbol-loader--small" />
      </div>
    );
  }

  return (
    <div className="BinSymbol">
      <div className="BinSymbol-symbolContainer">
        <div className="BinSymbol-name">{name}</div>
        <div className="BinSymbol-symbol">
          <svg width="53" height="96" viewBox="0 0 53 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M26.4999 -7.29311e-05H30.7981L52.9997 10.1758L53 61.9442L30.7984 96H22.2019L0.000269571 61.9442L0 10.1758L22.2016 -7.29311e-05H26.4999Z"
              fill="#BDBDBD"
            />
          </svg>
        </div>
      </div>
      <div className="BinSymbol-capacity">{renderCapacityLabel()}</div>
    </div>
  );
}

BinSymbol.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string,
  capacity: PropTypes.number,
};

export default BinSymbol;
