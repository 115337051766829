/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import moment from 'moment';
import PropTypes from 'prop-types';
import { CalendarIcon } from '../../atoms/Icons';
import FeedFloDatePicker from '../../molecules/FeedFloDatePicker';

function DateRangePicker({ visible = false, dateRange = null, setVisible = () => {}, setDateRange = () => {} }) {
  const fromLabel = `${dateRange.from.format('MMM')} ${dateRange.from.format('DD')}`;
  const toLabel = `${dateRange.to.format('MMM')} ${dateRange.to.format('DD')}`;

  const onDateChange = (e) => {
    if (e.dateRange) {
      const newFrom = moment(e.dateRange.from);
      const newTo = moment(e.dateRange.to);
      setDateRange({ ...dateRange, from: newFrom, to: newTo });
    }

    setVisible(false);
  };

  const hideDatePicker = () => {
    setVisible(false);
  };

  const toggleDatePicker = () => {
    setVisible(!visible);
  };

  return (
    <>
      <div className="dateRangeButton" onClick={toggleDatePicker}>
        <div className="icon">
          <CalendarIcon />
        </div>
        <div className="text">{`${fromLabel} - ${toLabel}`}</div>
      </div>
      {visible && (
        <FeedFloDatePicker
          onClickedOutside={hideDatePicker}
          onChange={onDateChange}
          from={dateRange.from.toDate()}
          to={dateRange.to.toDate()}
        />
      )}
    </>
  );
}

DateRangePicker.propTypes = {
  visible: PropTypes.bool.isRequired,
  dateRange: PropTypes.object,
  setVisible: PropTypes.func.isRequired,
  setDateRange: PropTypes.func.isRequired,
};

export default DateRangePicker;
