/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import FeedFloTextInput from '../../atoms/FeedFloTextInput';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';
import FeedFloScopesList from '../../molecules/FeedFloScopesList';
import FeedFloButton from '../../atoms/FeedFloButton';
import Heading from '../../atoms/Heading';

import './FeedFloNewAccessKey.scss';

function FeedFloNewAccessKey({ onGenerate, onCancel }) {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [label, setLabel] = useState('');
  const [expiryPeriodInDays, setExpiryPeriodDays] = useState(30);
  const questionTag = `What's this key for?`;
  let expiryTag = `The key will never expire`;
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const [hasPressedSaveOnce, setHasPressedSaveOnce] = useState('');

  if (expiryPeriodInDays > 0)
    expiryTag = `The key will expire on  ${moment().add(expiryPeriodInDays, 'days').format('MMMM Do YYYY')}`;

  return (
    <>
      <div className="FeedFloNewAccessKeyBlur" />
      <div className="FeedFloNewAccessKey">
        <Heading text="New Access Key" />
        <div className={`row note ${hasPressedSaveOnce && label.length < 1 ? 'error' : ''} `}>
          <FeedFloTextInput
            onChange={(v) => {
              setLabel(v);
            }}
            label="Note"
            text={label}
          />
        </div>
        <div className="explanation question">{questionTag} </div>
        <div className="row expiry">
          <FeedFloDropDown
            label="Expiration"
            defaultTitle="30 days"
            list={[
              { id: 0, name: '30 days', selected: expiryPeriodInDays === 30, days: 30 },
              { id: 1, name: '60 days', selected: expiryPeriodInDays === 60, days: 60 },
              { id: 2, name: '90 days', selected: expiryPeriodInDays === 90, days: 90 },
              { id: 3, name: 'none', selected: expiryPeriodInDays === -1, days: -1 },
            ]}
            onChange={(item) => {
              setExpiryPeriodDays(item.days);
            }}
          />
          <div className="explanation center">{expiryTag}</div>
        </div>
        <div className="scopes">
          <label>Select Scopes</label>
          <div className="scopeDescription">Scopes define the access for personal keys.</div>
          <div className={`${hasPressedSaveOnce && selectedRoles.length < 1 ? 'error' : ''} `}>
            <FeedFloScopesList
              list={[
                {
                  id: 'data-science',
                  name: 'Data Scientist',
                  description: 'Access animal consumption data',
                  selected: false,
                },
                {
                  id: 'feed-manager',
                  name: 'Feed Manager',
                  description: 'Gain read access to our database',
                  selected: false,
                },
              ]}
              onChange={(listOfRoles) => setSelectedRoles(listOfRoles.filter((role) => role.selected))}
            />
          </div>
        </div>
        <div className="buttons">
          <div className="green">
            <FeedFloButton
              disabled={buttonsDisabled}
              onClick={() => {
                setHasPressedSaveOnce(true);
                if (label.length > 0) {
                  setButtonsDisabled(true);
                  onGenerate({ label, expiryPeriodInDays, roles: selectedRoles.map((r) => r.id) });
                }
              }}
            >
              Generate Key
            </FeedFloButton>
          </div>
          <FeedFloButton
            type="secondary"
            disabled={buttonsDisabled}
            onClick={() => {
              setButtonsDisabled(true);
              onCancel();
            }}
          >
            Cancel
          </FeedFloButton>
        </div>
      </div>
    </>
  );
}

FeedFloNewAccessKey.propTypes = {
  onGenerate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default FeedFloNewAccessKey;
