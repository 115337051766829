import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FeedFloNotificationsOptions from '../../../organisms/FeedFloNotificationsOptions';
import FeedFloSearchBar from '../../../molecules/FeedFloSearchBar';
import FeedFloWatchListRow from '../../../molecules/FeedFloWatchListRow';
import './NotificationSettings.scss';

function NotificationSettingsView({
  allBarns = [],
  watchedBarnIDs: watchedBarnIDsProp,
  notificationTimePeriods: notificationTimePeriodsProp,
  onChange = () => {},
}) {
  const [notificationTimePeriods, setNotificationTimePeriods] = useState(notificationTimePeriodsProp);
  const [watchedBarnIDs, setWatchedBarnIDs] = useState(watchedBarnIDsProp);
  const barnLookup = allBarns.reduce((dict, barn) => {
    dict[barn.id] = barn;
    return dict;
  }, {});

  const onNotificationSectionChange = (updatedTimePeriods, type, service, isValid) => {
    setNotificationTimePeriods((prev) => {
      const newValue = {
        ...prev,
      };
      newValue[type][service] = updatedTimePeriods;
      onChange({ watchedBarnIDs, notificationTimePeriods: newValue, isValid });
      return newValue;
    });
  };

  const createNotificationSection = () => {
    return (
      <>
        <React.Fragment key="EmptyPipeWrapperNotif">
          <div key="EmptyPipeNotifDivider" className="notifDivider" />
          <div className="rowNotif">
            <div className="alertTypeRow alertColNotif noselect">Empty Pipe Alerts</div>
            <div className=" smsColNotif alignList">
              <FeedFloNotificationsOptions
                currentTimePeriods={notificationTimePeriods?.empty?.sms}
                onChange={(updatedTimes, isValid) => {
                  onNotificationSectionChange(updatedTimes, 'empty', 'sms', isValid);
                }}
                iconType="chat"
                config={{
                  initial: {
                    title: 'New Notification',
                    defaultSet: 10800, // everything will be in seconds
                    off: -1,
                  },
                }}
              />
            </div>
            <div className=" emailColNotif emailRow alignList">
              <FeedFloNotificationsOptions
                currentTimePeriods={notificationTimePeriods?.empty?.email}
                onChange={(updatedTimes, isValid) => {
                  onNotificationSectionChange(updatedTimes, 'empty', 'email', isValid);
                }}
                iconType="email"
                config={{
                  initial: {
                    title: 'New Notification',
                    defaultSet: 10800, // everything will be in seconds
                    off: -1,
                  },
                  reminder: {
                    title: 'Reminder Notification',
                    defaultSet: 21600,
                    off: -1,
                  },
                }}
              />
            </div>
          </div>
        </React.Fragment>
        <React.Fragment key="InactivePipeWrapperNotif">
          <div key="InactivePipeNotifDivider" className="notifDivider" />
          <div className="rowNotif">
            <div className="alertTypeRow alertColNotif noselect">Inactive Auger Alerts</div>
            <div className=" smsColNotif alignList">
              <FeedFloNotificationsOptions
                currentTimePeriods={notificationTimePeriods?.inactive?.sms}
                onChange={(updatedTimes, isValid) => {
                  onNotificationSectionChange(updatedTimes, 'inactive', 'sms', isValid);
                }}
                iconType="chat"
                config={{
                  initial: {
                    title: 'New Notification',
                    defaultSet: 21600, // everything will be in seconds
                    off: -1,
                  },
                }}
              />
            </div>
            <div className=" emailColNotif emailRow alignList">
              <FeedFloNotificationsOptions
                currentTimePeriods={notificationTimePeriods?.inactive?.email}
                onChange={(updatedTimes, isValid) => {
                  onNotificationSectionChange(updatedTimes, 'inactive', 'email', isValid);
                }}
                iconType="email"
                config={{
                  initial: {
                    title: 'New Notification',
                    defaultSet: 21600, // everything will be in seconds
                    off: -1,
                  },
                  reminder: {
                    title: 'Reminder Notification',
                    defaultSet: 43200,
                    off: -1,
                  },
                }}
              />
            </div>
          </div>
        </React.Fragment>
      </>
    );
  };

  const createWatchList = () => {
    const barnsOnList = allBarns.filter((b) => !watchedBarnIDs.includes(b.id));
    return (
      <>
        <React.Fragment key={`${Math.random()}wrapperNotif`}>
          <div key={`${Math.random()}notifDivider`} className="notifDivider" />
          <div className="headerNotif">
            <div className="alertTypeHeader alertColNotif noselect">Watch List</div>
          </div>
          <div className="spacing noselect">
            <FeedFloSearchBar
              placeholder="Barn"
              items={barnsOnList.map((b) => {
                return { id: b.id, label: b.name };
              })}
              onChange={(barnId) => {
                if (!watchedBarnIDs.includes(barnId)) {
                  const joined = watchedBarnIDs.concat(barnId);
                  setWatchedBarnIDs(joined);
                  onChange({ watchedBarnIDs: joined, notificationTimePeriods, isValid: true });
                }
              }}
            />
          </div>
          <div className="headerNotif">
            <div className="alertTypeHeader alertColNotif noselect">Currently Watching</div>
          </div>
          <div className="spacing noselect">
            {watchedBarnIDs.map((bID) => {
              return (
                <FeedFloWatchListRow
                  key={bID}
                  label={barnLookup[bID].name}
                  onDelete={() => {
                    const newBarnList = watchedBarnIDs.filter((b) => b !== bID);
                    setWatchedBarnIDs(newBarnList);
                    onChange({ watchedBarnIDs: newBarnList, notificationTimePeriods, isValid: true });
                  }}
                />
              );
            })}
          </div>
        </React.Fragment>
      </>
    );
  };

  return (
    <div className="NotificationSettings">
      <div className="headerNotif">
        <div className="alertTypeHeader alertColNotif">Alert Type</div>
        <div className="smsHeader smsColNotif">SMS</div>
        <div className="emailHeader emailColNotif">Email</div>
      </div>
      {createNotificationSection()}
      {createWatchList()}
    </div>
  );
}

NotificationSettingsView.propTypes = {
  watchedBarnIDs: PropTypes.array.isRequired,
  allBarns: PropTypes.array.isRequired,
  notificationTimePeriods: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default NotificationSettingsView;
