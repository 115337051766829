/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import BarnConsumptionTile from './BarnConsumptionTile';
import { convertGramsToSmallUnits } from '../../utils/unitConversion';
import WebAppContext from '../../utils/webAppContext';
import { useFeedFrameFilter, useAnalysisFilter } from '../../utils/useFeedFrameFilter';

const FEED_FRAME_GQL = gql`
  query FeedFrameQuery($feedFrameWhere: feed_frame_bool_exp, $feedFrameAnalysisWhere: feed_frame_analysis_bool_exp) {
    feed_frame(where: $feedFrameWhere, order_by: { started_at: asc }) {
      started_at
      ended_at
      feed_frame_analyses(where: $feedFrameAnalysisWhere, limit: 1, order_by: { created_at: desc_nulls_last }) {
        mass_moved_in_grams
      }
    }
  }
`;

function BarnConsumptionController({ barnId, dateRange, view }) {
  const [barnExpected, setBarnExpected] = useState(0); // to be collected in the future
  const [percent, setPercent] = useState(0); // percent will depend on the expected values
  const { isMetric } = useContext(WebAppContext);

  const feedFrameFilter = useFeedFrameFilter({
    feed_line: {
      farm_id: { _eq: barnId },
    },
    started_at: { _lte: dateRange.to },
    ended_at: { _gte: dateRange.from },
  });

  const analysisFilter = useAnalysisFilter();

  const { loading, error, data } = useQuery(FEED_FRAME_GQL, {
    variables: {
      feedFrameWhere: feedFrameFilter,
      feedFrameAnalysisWhere: analysisFilter,
    },
  });

  const { floEvents, usage } = useMemo(() => {
    const feedFrames = data?.feed_frame;
    let usage = 0;

    const floEvents = feedFrames
      ?.map((r, i) => {
        const massG = r?.feed_frame_analyses?.[0]?.mass_moved_in_grams || 0;
        const massDisplay = convertGramsToSmallUnits(isMetric, massG);

        const event = {
          s: r?.started_at * 1000,
          e: r?.ended_at * 1000,
          v: massDisplay,
        };

        usage += massDisplay;
        return event;
      })
      .filter((e) => e.v !== 0);
    return { floEvents, usage };
  }, [data]);

  if (error) return <>{JSON.stringify(error)}</>;
  return (
    <>
      {floEvents && (
        <BarnConsumptionTile
          percent={percent}
          usage={usage}
          expected={barnExpected}
          start={dateRange.from * 1000}
          end={dateRange.to * 1000}
          floEvents={floEvents}
          view={view}
        />
      )}
    </>
  );
}

BarnConsumptionController.propTypes = {
  barnId: PropTypes.string.isRequired,
  dateRange: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
};

export default BarnConsumptionController;
