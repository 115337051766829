import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { gql, useQuery } from '@apollo/client';
import ListOfButtons from './ListOfButtons';
import { HomeIcon, StoreIcon, AlertIcon, MoneyIcon, EKGIcon, ExportIcon, MapIcon, KeyIcon } from '../../atoms/Icons';
import './SideBar.scss';

const ORG_GQL = gql`
  query OrgListQuery {
    organization {
      name
      id
    }
  }
`;

const ALL_BUTTONS = [
  {
    url: '/',
    icon: <HomeIcon />,
    text: 'Barns',
    pathMatch: ['/', '/b/:bid/consumption'],
  },
  // {
  //   url: '/overview',
  //   icon: <ReportIcon />,
  //   text: 'Overview',
  // },
  {
    url: '/alerts/',
    icon: <AlertIcon />,
    text: 'Alerts',
  },
  {
    url: '/map',
    icon: <MapIcon />,
    text: 'Barn Map',
  },
  {
    url: '/exports',
    icon: <ExportIcon />,
    text: 'Exports',
  },
];

function SideBarView({ open, onClickOutside }) {
  const { user } = useAuth0();
  const sideBarRef = useRef(null);
  const { data } = useQuery(ORG_GQL);
  const orgList = data?.organization;

  const buttons = [...ALL_BUTTONS];

  if (user?.['https://feedflo.com/roles']?.includes('researcher')) {
    buttons.push({
      url: '/barnStatus',
      icon: <EKGIcon />,
      text: 'Barn Status',
    });
    buttons.push({
      url: '/firmware',
      icon: <MoneyIcon />,
      text: 'Firmware Control',
    });
    buttons.push({
      url: '/apiAccess',
      icon: <KeyIcon />,
      text: 'Api Access',
    });
  }

  const handleClickOutside = (event) => {
    if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  let orgName = 'Multiple';
  if (orgList?.length === 0) orgName = '';
  else if (orgList?.length === 1) orgName = orgList[0].name;
  else orgName = 'Multiple Orgs';

  return (
    <div ref={sideBarRef} className={`SideBarView ${open ? 'open' : ''}`}>
      <div className="org">
        <div className="whiteCircle">
          <StoreIcon />
        </div>
        <div className="orgName">{orgName}</div>
        <ListOfButtons buttons={buttons} />
      </div>
    </div>
  );
}

SideBarView.propTypes = {
  open: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func.isRequired,
};

export default SideBarView;
