/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { gql, useQuery, useApolloClient } from '@apollo/client';
import moment from 'moment';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';

const FW_STREAMS_GQL = gql`
  query AllStreamsQuery {
    firmware_stream(where: { deleted_at: { _is_null: true } }) {
      name
      id
      firmwares(where: { deleted_at: { _is_null: true } }, order_by: { created_at: desc }) {
        tag
        name
        created_at
        id
      }
    }
  }
`;

export default function FirmwareStreamController() {
  const client = useApolloClient();
  const { loading, data } = useQuery(FW_STREAMS_GQL);
  const firmwareStreams = data?.firmware_stream;

  const ErrorOccurred = (errorText = 'Error Occurred') =>
    toast.warn(errorText, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const downloadFWFile = async (fw) => {
    const { error, data } = await client.query({
      query: gql`
        query DownloadFW($fw_id: uuid!) {
          firmware(where: { id: { _eq: $fw_id } }) {
            firmware_file
          }
        }
      `,
      variables: { fw_id: fw.id },
    });

    if (error) ErrorOccurred('Error Downloading Firmware');

    const binary = [];
    const string = data.firmware[0].firmware_file.substring(2);
    for (let i = 0; i < string.length / 2; i += 1) {
      const h = string.substr(i * 2, 2);
      binary[i] = parseInt(h, 16);
    }

    const byteArray = new Uint8Array(binary);

    const blob = new Blob([byteArray], {
      type: 'application/octet-stream',
    });
    saveAs(blob, `${fw.name}-${fw.tag}.bin`);
  };

  const buildStreamRow = (stream) => {
    return (
      <div className="fw" key={stream.id}>
        <span>
          <h3>{stream.name}: </h3>
        </span>

        {stream.firmwares.length > 0 && (
          <>
            <div className="spacer">
              <div className="fwHistoryWindow customScroll">
                {stream.firmwares.map((fw, i) => {
                  return (
                    <div className="fwHistory" key={fw.id}>
                      <b>{fw.name}</b> {i === 0 && <span className="live"> - Live - </span>}
                      <br />
                      {moment(fw.created_at * 1000).format('MMM Do YYYY - H:mm')}
                      <br />
                      Tag: <span className="tag">{fw.tag}</span>
                      <br />
                      <a
                        className="download"
                        onClick={() => {
                          downloadFWFile(fw);
                        }}
                      >
                        Download
                      </a>
                      <hr />
                    </div>
                  );
                })}
              </div>
            </div>
            <div>{}</div>
          </>
        )}
      </div>
    );
  };

  if (loading) return <>Loading</>;
  return (
    <div className="FirmwareStreamController">
      <div>{firmwareStreams.map(buildStreamRow)}</div>
    </div>
  );
}
