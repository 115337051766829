import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import Heading from '../../atoms/Heading';
import BinSetSection from '../../organisms/BinSetSection';
import './BarnEquipmentTab.scss';

const GET_BIN_SETS_GQL = gql`
  query EquipmentPageQuery($barn_id: uuid!) {
    farm(where: { id: { _eq: $barn_id } }) {
      bin_sets {
        id
        bins {
          name
          capacity_in_grams
          deliveries(limit: 1, order_by: { delivered_at: desc }) {
            delivered_at
          }
        }
        bin_level_overrides(limit: 1, order_by: { created_at: desc }) {
          created_at
        }
      }
    }
  }
`;

function BarnEquipmentTab({ barnId = '' }) {
  const { loading, data } = useQuery(GET_BIN_SETS_GQL, {
    fetchPolicy: 'no-cache',
    variables: { barn_id: barnId },
  });
  const binSets = data?.farm[0]?.bin_sets;

  const renderBinsAndSets = () => {
    if (loading) {
      return (
        <>
          <BinSetSection loading={true} />
          <BinSetSection loading={true} />
        </>
      );
    }

    return (
      <>
        {binSets?.map((binSet) => (
          <BinSetSection key={binSet.id} binSet={binSet} />
        ))}
      </>
    );
  };

  return (
    <div className="BarnEquipmentTab-tabContent">
      <Heading className="BarnEquipmentTab-subheading" text="Sets & Bins" underline={true} />
      <div className="BarnEquipmentTab-binSetsContainer">{renderBinsAndSets()}</div>
    </div>
  );
}

BarnEquipmentTab.propTypes = {
  barnId: PropTypes.string.isRequired,
};

export default BarnEquipmentTab;
