/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/plain.css';
import './FeedFloNumInput.scss';

function FeedFloNumInput({
  className = '',
  inputClassName = '',
  label = '',
  disabled = false,
  value: valueProp = '',
  onChange = () => {},
}) {
  const [valueString, setValueString] = useState('');

  useEffect(() => {
    setValueString(valueProp);
  }, [valueProp]);

  return (
    <div className={`FeedFloNumInput ${className}`}>
      <label className={`${disabled ? 'disabled' : ''}`}>
        {label}
        <input
          onChange={(e) => {
            const newInputValue = e.target.value.replace(/[^0-9]/g, '');
            setValueString(newInputValue);
            onChange(newInputValue);
          }}
          className={`textBox ${disabled ? 'disabled' : ''} ${inputClassName}`}
          type="text"
          value={typeof valueString === 'string' ? valueString : ''}
          disabled={disabled}
        />
      </label>
    </div>
  );
}

FeedFloNumInput.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default FeedFloNumInput;
