/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import FeedFloButton from 'atoms/FeedFloButton';
import APIInfoTile from 'molecules/APIInfoTile';
import FeedFloNewAccessKey from 'organisms/FeedFloNewAccessKey';
import DeleteConfirmation from 'organisms/DeleteConfirmation';
import './FeedFloApiTokenController.scss';

const GET_TOKENS_GQL = gql`
  query GetUsersTokens {
    api_access_token(
      where: { revoked_at: { _is_null: true }, deleted_at: { _is_null: true } }
      order_by: { created_at: desc }
    ) {
      created_at
      expires_at
      label
      id
      revoked_at
      details
    }
  }
`;

const NEW_TOKEN_GQL = gql`
  mutation NewTokenMutation($roles: [String!]!, $label: String!, $expires_at: Int) {
    api_access_token_creation(roles: $roles, label: $label, expires_at: $expires_at) {
      id
      token
      expires_at
    }
  }
`;

const REVOKE_TOKEN_GQL = gql`
  mutation RevokeTokenMutation($tokenId: uuid!, $now: bigint!) {
    update_api_access_token(where: { id: { _eq: $tokenId } }, _set: { revoked_at: $now }) {
      affected_rows
    }
  }
`;

function FeedFloApiTokenController() {
  // Modal States
  const [newTokenModalOpen, setNewTokenModalOpen] = useState(false);
  const [revokeTokenModalOpen, setRevokeTokenModalOpen] = useState(false);
  const [tokenIdToDelete, setTokenIdToDelete] = useState(null);

  const { loading, data } = useQuery(GET_TOKENS_GQL);
  const tokens = data?.api_access_token || null;

  const [newToken, { data: newTokenData }] = useMutation(NEW_TOKEN_GQL);
  const [revokeToken] = useMutation(REVOKE_TOKEN_GQL);

  async function handelGenerateNewToken({ label, expiryPeriodInDays, roles }) {
    const expiry = moment().add(expiryPeriodInDays, 'days').unix();
    newToken({
      variables: {
        roles,
        label,
        expires_at: expiry,
      },
      refetchQueries: ['GetUsersTokens'],
    });
  }

  async function handelRevokeToken(tokenId) {
    revokeToken({
      variables: {
        tokenId,
        now: new Date().getUTCSeconds(),
      },
      refetchQueries: ['GetUsersTokens'],
    });
  }

  const PERMISSION_TEXT_MAP = {
    'data-science': 'read: farms, lines, feed events',
    'feed-manager': 'read & write: bins, bin levels, deliveries, feed profiles',
  };

  function createTokenRow(token) {
    const permissions = PERMISSION_TEXT_MAP[token?.details?.roles?.[0]] || 'None';
    let dateString = 'No Expiry';
    let secret = null;
    if (token.id === newTokenData?.api_access_token_creation?.id) {
      secret = newTokenData?.api_access_token_creation?.token;
    }

    if (token.expires_at) {
      dateString = moment(token.expires_at * 1000).format('MMMM Do YYYY');
    }
    return (
      <>
        <APIInfoTile
          title={token.label}
          permissions={permissions}
          date={dateString}
          secretToken={secret}
          onDelete={() => {
            setRevokeTokenModalOpen(true);
            setTokenIdToDelete(token.id);
          }}
        />
      </>
    );
  }

  const newTokenModalView = (
    <FeedFloNewAccessKey
      onCancel={() => {
        setNewTokenModalOpen(false);
      }}
      onGenerate={async (options) => {
        await handelGenerateNewToken(options);
        setNewTokenModalOpen(false);
      }}
    />
  );
  const revokeTokenModalView = (
    <DeleteConfirmation
      onCancel={() => {
        setRevokeTokenModalOpen(false);
      }}
      onDelete={async () => {
        if (tokenIdToDelete) {
          await handelRevokeToken(tokenIdToDelete);
          setRevokeTokenModalOpen(false);
        }
      }}
    />
  );

  return (
    <div className="FeedFloApiTokenController">
      {newTokenModalOpen ? newTokenModalView : null}
      {revokeTokenModalOpen ? revokeTokenModalView : null}
      <h2>Personal access tokens</h2>
      <div className="ButtonHolder">
        <FeedFloButton
          onClick={() => {
            setNewTokenModalOpen(true);
          }}
        >
          Generate New Token
        </FeedFloButton>
      </div>

      <div className="TokenRowHolder">
        <div className="TokenListMessage">
          {loading && <>Loading your tokens... </>}
          {tokens?.length === 0 && <> Create your first access token. </>}
          {tokens?.length > 0 && tokens.map(createTokenRow)}
        </div>
      </div>
    </div>
  );
}

FeedFloApiTokenController.propTypes = {};

export default FeedFloApiTokenController;
