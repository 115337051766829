/* eslint-disable guard-for-in */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChatIcon, EmailIcon } from '../../atoms/Icons';
import usePrevious from '../../utils/hooks/usePrevious';
import FeedFloNotificationTimeSetting from './FeedFloNotificationTimeSetting';
import './FeedFloNotificationsOptions.scss';

const currentTimePeriodDefaults = {
  initial: -1,
  reminder: -1,
};

function FeedFloNotificationsOptions({
  iconType = '',
  config = {},
  currentTimePeriods: currTimeProps = currentTimePeriodDefaults,
  onChange = () => {},
}) {
  const [currentTimePeriods, setCurrentTimePeriods] = useState(currTimeProps);
  const [open, setOpen] = useState(false);
  const prevTimes = usePrevious(currentTimePeriods);
  const OFF_STATE = -1;

  const hasError = [currentTimePeriods.initial, currentTimePeriods.reminder].reduce((prev, curr) => {
    const MIN_TIME_FOR_CUSTOM = 20 * 60; // minimum 20 minutes
    return (curr !== OFF_STATE && curr < MIN_TIME_FOR_CUSTOM) || prev;
  }, false);
  const hasBeenSet = currentTimePeriods.initial > 0;

  useEffect(() => {
    const compareTimes = typeof prevTimes === 'undefined' ? currTimeProps : prevTimes;
    const sameAsPrev = JSON.stringify(compareTimes) === JSON.stringify(currentTimePeriods);

    if (!sameAsPrev) {
      onChange(currentTimePeriods, !hasError);
    }
  }, [currentTimePeriods]);

  const dropCheckRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropCheckRef.current && !dropCheckRef.current.contains(event.target) && !hasError) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropCheckRef, hasError]);

  function getNotificationTimeSetting(type) {
    const usingOff = config[type].off === currentTimePeriods[type];
    const disabled = type === 'reminder' && currentTimePeriods.initial === OFF_STATE;
    const params = {
      usingOff,
      title: config[type].title,
      defaultSet: config[type].defaultSet,
      offState: config[type].off,
      customTime: currentTimePeriods[type],
      disabled,
      onChange: (time) =>
        setCurrentTimePeriods((prev) => {
          return {
            ...prev,
            [type]: time,
          };
        }),
    };
    return <FeedFloNotificationTimeSetting key={type} title="New Notification" {...params} />;
  }

  function getNotificationTimeSettings() {
    const output = [];
    for (const type in config) {
      output.push(getNotificationTimeSetting(type));
    }
    return output;
  }

  const iconEl =
    iconType === 'chat' ? (
      <div className="chat">
        <ChatIcon />
      </div>
    ) : (
      <EmailIcon />
    );

  return (
    <div ref={dropCheckRef} className="FeedFloNotificationsOptions noselect">
      <div className="optionsheader">
        <div className={`icon ${hasBeenSet ? 'isSet' : ''} ${hasError ? 'error' : ''} `}>
          <div
            onClick={() => {
              setOpen((prevState) => {
                return !hasError ? !prevState : true;
              });
            }}
          >
            {iconEl}
          </div>
        </div>
      </div>
      {open && <div className="list">{getNotificationTimeSettings()}</div>}
    </div>
  );
}

FeedFloNotificationsOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
  iconType: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  currentTimePeriods: PropTypes.object,
};

export default FeedFloNotificationsOptions;
