import moment from 'moment';

export default function useDefaultDateRange(barnId) {
  const today = moment().endOf('day');
  const sevenDaysAgo = moment().subtract(6, 'd').startOf('day');

  switch (barnId) {
    case '39357f50-4520-4417-ba97-85ae5969d862': // Good Test Barn
      return { from: moment('2022/02/13'), to: moment('2022/02/27').subtract(1, 'seconds') };
    case 'dd56825e-aed5-4a11-8d85-27fcd3847bd5': // Bad Test Barn
      return { from: moment('2021/10/31'), to: moment('2021/11/17').subtract(1, 'seconds') };
    default:
      return { from: sevenDaysAgo, to: today };
  }
}
