import React from 'react';
import { ComingSoonIcon } from '../../atoms/Icons';
import Heading from '../../atoms/Heading';

import './DevelopersPage.scss';

export default function DevelopersPage() {
  return (
    <div className="DevelopersPage">
      <Heading text="Developers" />
      <div className="placeHolder">
        <ComingSoonIcon />
      </div>
    </div>
  );
}
