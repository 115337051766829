import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';
import BinSymbol from '../../atoms/BinSymbol/BinSymbol';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import './BinSetSection.scss';

function BinSetSection({ loading = false, binSet = {} }) {
  const renderHeading = () => {
    const binNames = binSet?.bins?.map((bin) => bin.name);
    let binPrefix = '';
    let binList = '';

    if (binNames.length === 1) {
      binPrefix = 'Bin';
      binList = [binNames];
    } else if (binNames.length === 2) {
      binPrefix = 'Bins';
      binList = binNames.join(' & ');
    } else {
      binPrefix = 'Bins';
      // Take all bins excluding the last one, join them with commas, then append the last one with &
      binList = `${binNames.slice(0, binNames.length - 1).join(', ')} & ${binNames[binNames.length - 1]}`;
    }

    return `${binPrefix} ${binList}:`;
  };

  const renderLastDelivery = (distance = true) => {
    let deliveredAtTotal = 0;
    let deliveryCount = 0;

    binSet?.bins.forEach((bin) => {
      if (bin?.deliveries?.length === 1 && bin?.deliveries?.[0]?.delivered_at) {
        deliveredAtTotal += bin.deliveries[0].delivered_at;
        deliveryCount++;
      }
    });

    if (!deliveryCount) {
      return 'Never';
    }

    const deliveredAtAverage = Math.ceil(deliveredAtTotal / deliveryCount);

    if (distance) {
      return formatDistance(new Date(deliveredAtAverage * 1000), new Date(), { addSuffix: true });
    }

    return new Date(deliveredAtAverage * 1000);
  };

  const renderLastOverride = (distance = true) => {
    if (!binSet?.bin_level_overrides?.length !== 1 && !binSet?.bin_level_overrides?.[0]?.created_at) {
      return 'Never';
    }

    if (distance) {
      return formatDistance(new Date(binSet.bin_level_overrides[0].created_at * 1000), new Date(), { addSuffix: true });
    }

    return new Date(binSet.bin_level_overrides[0].created_at * 1000);
  };

  if (loading) {
    return (
      <div className="BinSetSection">
        <LoadingSkeleton className="BinSetSection-loader BinSetSection-loader--medium" />
        <div className="BinSetSection-content">
          <div className="BinSetSection-symbolContainer">
            <BinSymbol loading={true} />
            <BinSymbol loading={true} />
          </div>
          <div className="BinSetSection-detailsContainer">
            <LoadingSkeleton className="BinSetSection-loader BinSetSection-loader--medium" />
            <LoadingSkeleton className="BinSetSection-loader BinSetSection-loader--medium" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="BinSetSection">
      <h3 className="BinSetSection-heading">{renderHeading()}</h3>
      <div className="BinSetSection-content">
        <div className="BinSetSection-symbolContainer">
          {binSet?.bins?.map((bin) => (
            <BinSymbol key={bin.name} name={bin.name} capacity={bin.capacity_in_grams} />
          ))}
        </div>
        <div className="BinSetSection-detailsContainer">
          <div className="BinSetSection-detailsRow">
            <span className="BinSetSection-detailsLabel">Last Delivery:</span>
            <span className="BinSetSection-details" title={renderLastDelivery(false)}>
              {renderLastDelivery(true)}
            </span>
          </div>
          <div className="BinSetSection-detailsRow">
            <span className="BinSetSection-detailsLabel">Last Override:</span>
            <span className="BinSetSection-details" title={renderLastOverride(false)}>
              {renderLastOverride(true)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

BinSetSection.propTypes = {
  loading: PropTypes.bool,
  binSet: PropTypes.object,
};

export default BinSetSection;
