import React from 'react';
import PropTypes from 'prop-types';
import momentTZ from 'moment-timezone';
import FeedFloDropDown from '../../../atoms/FeedFloDropDown';

function TimezoneController({ disabled = false, onChange = () => {} }) {
  const timezones = momentTZ.tz.names();

  function handleTimezoneChange(id, item) {
    onChange(id, item);
  }

  const timezoneDropDownList = timezones.map((tz, i) => {
    const currentLocalTimezone = momentTZ.tz.guess();
    return {
      id: i,
      name: tz,
      selected: currentLocalTimezone === tz,
    };
  });

  return (
    <FeedFloDropDown
      id="timezone"
      label="Timezone"
      list={timezoneDropDownList}
      defaultSelected="Select a timezone"
      onChange={(id, item) => handleTimezoneChange(id, item)}
      disabled={disabled}
    />
  );
}

TimezoneController.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default TimezoneController;
