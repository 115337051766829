/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import Heading from '../../atoms/Heading';
import './BarnStatusPage.scss';

const BARN_GQL = gql`
  query BarnStatus {
    organization {
      name
      id
      farms {
        lat: latitude
        lon: longitude
        id
        name
        feed_lines {
          device_assignments {
            device {
              transactions(limit: 1, order_by: { occured_at: desc }, where: { deleted_at: { _is_null: true } }) {
                occured_at
              }
            }
          }
        }
      }
    }
  }
`;

export default function BarnStatusPage() {
  const [orgData, setOrgData] = useState([]);
  const [onlineFarms, setOnlineFarms] = useState([]);
  const { loading, data, error } = useQuery(BARN_GQL);
  useEffect(() => {
    if (!loading) {
      const newOnlineFarmsList = [];
      data.organization.forEach((org) => {
        org.farms.forEach((barn) => {
          const mostRecentTime = barn.feed_lines.reduce((max, curr) => {
            const currTime = curr?.device_assignments[0]?.device?.transactions[0]?.occured_at || 0;
            if (max < currTime) {
              return currTime;
            }
            return max;
          }, 0);
          const online = mostRecentTime * 1000 + 4 * 60 * 60 * 1000 > new Date().getTime();
          if (online) {
            newOnlineFarmsList.push(barn.id);
          }
        });
      });
      setOnlineFarms(newOnlineFarmsList);
      setOrgData(data.organization);
    }
  }, [data]);

  if (loading) return <>Loading 5 minute wait</>;
  if (error) return <>{JSON.stringify(error)}</>;

  return (
    <div className="BarnStatusPage">
      {orgData.map((org) => {
        return (
          <div key={org.id}>
            <Heading text={org.name} />
            <div className="farmHolder" key={`farmHolder${org.id}`}>
              {org.farms.map((barn) => {
                const online = onlineFarms.includes(barn.id);
                return (
                  <div key={`spacing${barn.id}`} className="spacingForTiles">
                    <Link key={`spacing${barn.id}`} to={`/b/${barn.id}/consumption`}>
                      <div className="barnTile" key={`barnTile${barn.id}`}>
                        <div className="title" key={`title${barn.id}`}>
                          {barn.name}
                        </div>
                        <img
                          key={`img${barn.id}`}
                          src={`/icons/map/${online ? 'onlineBarnMapIcon.png' : 'offlineBarnMapIcon.png'}`}
                          alt={online ? 'Online' : 'Offline'}
                        />
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
