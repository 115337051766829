import React from 'react';
import PropTypes from 'prop-types';
import './StatusBadge.scss';

function StatusBadge({ status = '', text = '' }) {
  let iconName = '';

  switch (status) {
    case 'error':
      iconName = 'error.png';
      break;
    case 'success':
      iconName = 'checkmark.png';
      break;

    case 'warning':
      iconName = 'ekg-sepia.png';
      break;

    default:
      iconName = 'ellipsis.png';
      break;
  }

  const label = text ? <div className="label">{text}</div> : null;

  return (
    <div className={`StatusBadge ${status}`}>
      {label}
      <img src={`/icons/${iconName}`} alt={status} className="icon" />
    </div>
  );
}

StatusBadge.propTypes = {
  text: PropTypes.string,
  status: PropTypes.string.isRequired,
};

export default StatusBadge;
