/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext } from 'react';

import moment from 'moment';
import { gql, useQuery, useApolloClient } from '@apollo/client';
import { saveAs } from 'file-saver';

import { CalendarIcon, ExportIcon, Refresh } from '../../atoms/Icons';
import FeedFloDatePicker from '../../molecules/FeedFloDatePicker';
import FeedFloButton from '../../atoms/FeedFloButton';
import FeedFloDropCheckBox from '../../molecules/FeedFloDropCheckBox';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';
import WebAppContext from '../../utils/webAppContext';

import DocumentFactory from '../../utils/DocumentFactory/factory';
import CSVFeedEventStrategy from '../../utils/DocumentFactory/csvFeedEventStrategy';
import XlsxFeedEventStrategy from '../../utils/DocumentFactory/xlsxFeedEventStrategy';
import CSVFaultCodeStrategy from '../../utils/DocumentFactory/csvFaultCodeStrategy';
import XlsxFaultCodeStrategy from '../../utils/DocumentFactory/xlsxFaultCodeStrategy';
import FeedFloProgress from '../../atoms/FeedFloProgress';

const FARM_GQL = gql`
  query FarmDataQuery {
    farm(order_by: { name: asc }) {
      id
      name
    }
  }
`;

export default function ExportsController() {
  const gqlClient = useApolloClient();
  const [progress, setProgress] = useState(0);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [fileFormat, setFileFormat] = useState(false);
  const [dataType, setDataType] = useState(false);
  const [massUnitSystem, setMassUnitsSystem] = useState(null);
  const [selectedFarmIds, setSelectedFarmIds] = useState([]);
  const [documentFactoryState, setDocumentFactoryState] = useState('ready');

  const { isMetric } = useContext(WebAppContext);

  const today = moment().endOf('day');
  const sevenDaysAgo = moment().subtract(6, 'd').startOf('day');
  const [dateRange, setDateRange] = useState({ from: sevenDaysAgo, to: today });
  const fromLabel = `${dateRange.from.format('MMM')} ${dateRange.from.format('DD')}`;
  const toLabel = `${dateRange.to.format('MMM')} ${dateRange.to.format('DD')}`;

  const { data } = useQuery(FARM_GQL);
  const farms = data?.farm;

  useEffect(() => {
    setMassUnitsSystem(isMetric ? 'metric' : 'imperial');
  }, [isMetric]);

  function onDateChange(e) {
    if (e.dateRange) {
      const newFrom = moment(e.dateRange.from);
      const newTo = moment(e.dateRange.to);
      setDateRange({ ...dateRange, from: newFrom, to: newTo });
    }

    setShowDatePicker(false);
  }

  function startExport() {
    setDocumentFactoryState('downloading');
    let strategy = null;
    switch (`${fileFormat}|${dataType}`) {
      case 'csv|feedFrame':
        strategy = new CSVFeedEventStrategy(massUnitSystem === 'metric');
        break;
      case 'xlsx|feedFrame':
        strategy = new XlsxFeedEventStrategy(massUnitSystem === 'metric');
        break;
      case 'csv|faultCode':
        strategy = new CSVFaultCodeStrategy();
        break;
      case 'xlsx|faultCode':
        strategy = new XlsxFaultCodeStrategy();
        break;

      default:
        break;
    }

    const factory = new DocumentFactory({
      farmIds: selectedFarmIds,
      startTime: dateRange.from.toDate(),
      stopTime: dateRange.to.toDate(),
      strategy,
      progressCallback: (x) => setProgress(x),
      gqlClient,
    });

    factory.start().then(() => {
      const dateFormat = 'YYYY-MM-DD';
      const datePart = `${dateRange.from.format(dateFormat)}_${dateRange.to.format(dateFormat)}`;
      const barnPart = `${selectedFarmIds.length} barns`;
      const dataTypeString = dataType;
      saveAs(factory.createFile(), `${barnPart}_${dataTypeString}_${datePart}.${fileFormat}`);
      setDocumentFactoryState('done');
    });
  }

  const barnListItems = farms?.map((f) => {
    return {
      id: f.id,
      name: f.name,
      selected: false,
    };
  });

  const dateRangeInput = (
    <div className={`${barnListItems ? '' : 'disabled'}`}>
      <div
        className="dateRangeButton"
        onClick={() => {
          setShowDatePicker(!showDatePicker);
        }}
      >
        <div className="icon">
          <CalendarIcon />
        </div>
        <div className="text">{`${fromLabel} - ${toLabel}`}</div>
      </div>
      {showDatePicker && (
        <FeedFloDatePicker
          onClickedOutside={() => setShowDatePicker(false)}
          onChange={(e) => onDateChange(e)}
          from={dateRange.from.toDate()}
          to={dateRange.to.toDate()}
        />
      )}
    </div>
  );

  const formatList = barnListItems
    ? [
        { id: 'xlsx', name: 'Excel .xlsx' },
        { id: 'csv', name: 'Comma Separated Values .csv' },
      ]
    : [];

  const dataTypeList = barnListItems
    ? [
        { id: 'feedFrame', name: 'Feed Events' },
        { id: 'faultCode', name: 'Alerts' },
      ]
    : [];

  const unitList = barnListItems
    ? [
        { id: 'metric', name: 'Metric (kg)', selected: massUnitSystem === 'metric' },
        { id: 'imperial', name: 'Imperial (lbs)', selected: massUnitSystem === 'imperial' },
      ]
    : [];

  return (
    <div className="ExportController">
      <div className="rowContainer">
        <div className="cell">{dateRangeInput}</div>
        <div className="cell flexGrow">
          <FeedFloDropCheckBox
            id="BarnDropDown"
            list={barnListItems}
            defaultTitle="Barns"
            onChange={(e) => setSelectedFarmIds(e.filter((b) => b.selected).map((b) => b.id))}
          />
        </div>
        <div className="cell flexGrow">
          <FeedFloDropDown list={dataTypeList} onChange={(item) => setDataType(item.id)} defaultTitle="Data Type" />
        </div>
        <div className="cell flexGrow">
          <FeedFloDropDown list={unitList} onChange={(item) => setMassUnitsSystem(item.id)} defaultTitle="Units" />
        </div>
        <div className="cell flexGrow">
          <FeedFloDropDown list={formatList} onChange={(item) => setFileFormat(item.id)} defaultTitle="File Format" />
        </div>

        <div className="cell">
          {documentFactoryState === 'ready' && (
            <FeedFloButton onClick={() => startExport()} disabled={documentFactoryState !== 'ready'}>
              <div className="icon">
                <ExportIcon />
              </div>
              <div className="text">Export</div>
            </FeedFloButton>
          )}
          {documentFactoryState === 'downloading' && <FeedFloProgress percent={progress} />}
          {documentFactoryState === 'done' && (
            <FeedFloButton onClick={() => setDocumentFactoryState('ready')}>
              <div className="icon">
                <Refresh />
              </div>
              <div className="text">Reset</div>
            </FeedFloButton>
          )}
        </div>
      </div>
    </div>
  );
}
