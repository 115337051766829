/* eslint-disable no-unused-vars */
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { gql, useQuery } from '@apollo/client';

import FeedLineConsumptionTile from './FeedLineConsumptionTile';
import { convertGramsToSmallUnits } from '../../utils/unitConversion';
import WebAppContext from '../../utils/webAppContext';
import { useFeedFrameFilter, useAnalysisFilter } from '../../utils/useFeedFrameFilter';
import { FAULT_CODE_LOOKUP } from '../../utils/utils';

const FEED_FRAME_GQL = gql`
  query FeedFrameQuery(
    $line_id: uuid!
    $feedFrameWhere: feed_frame_bool_exp
    $feedFrameAnalysisWhere: feed_frame_analysis_bool_exp
    $codeFilters: [fault_bool_exp!]!
  ) {
    feed_line(where: { id: { _eq: $line_id } }) {
      name
      feed_frames(where: $feedFrameWhere, order_by: { started_at: asc }) {
        started_at
        ended_at
        feed_frame_analyses(where: $feedFrameAnalysisWhere, limit: 1, order_by: { created_at: desc_nulls_last }) {
          mass_moved_in_grams
        }
      }
      device_assignments(
        where: { status: { _eq: "active" }, deleted_at: { _is_null: true }, ended_at: { _is_null: true } }
      ) {
        device {
          faults(where: { started_at: { _is_null: false }, ended_at: { _is_null: true }, _or: $codeFilters }) {
            code
            started_at
            ended_at
          }
        }
      }
    }
  }
`;

function FeedLineConsumptionController({ lineId = '', barnId = '', dateRange = {}, view = 'cumulative' }) {
  const now = useMemo(() => new Date(), []);
  const { isMetric } = useContext(WebAppContext);

  const feedFrameFilter = useFeedFrameFilter({
    started_at: { _lte: dateRange.to },
    ended_at: { _gte: dateRange.from },
  });
  const analysisFilter = useAnalysisFilter();

  const { loading, error, data } = useQuery(FEED_FRAME_GQL, {
    variables: {
      feedFrameWhere: feedFrameFilter,
      feedFrameAnalysisWhere: analysisFilter,
      line_id: lineId,
      codeFilters: [
        { code: { _eq: 2001 }, started_at: { _lt: Math.floor(now?.getTime() / 1000) - 1 * 60 * 60 } },
        { code: { _eq: 2004 }, started_at: { _lt: Math.floor(now?.getTime() / 1000) - 12 * 60 * 60 } },
      ],
    },
  });

  const { floEvents, lineName, lineUsage } = useMemo(() => {
    const feedFrames = data?.feed_line[0].feed_frames;
    let lineUsage = 0;

    const floEvents = feedFrames?.map((r, i) => {
      const massG = r?.feed_frame_analyses?.[0]?.mass_moved_in_grams || 0;
      const massDisplay = convertGramsToSmallUnits(isMetric, massG);

      const event = {
        s: feedFrames[i].started_at * 1000,
        e: feedFrames[i].ended_at * 1000,
        v: massDisplay,
      };

      lineUsage += massDisplay;
      return event;
    });
    return { floEvents, lineName: data?.feed_line?.[0]?.name || 'Loading', lineUsage };
  }, [data]);

  let faultText = null;
  const faults = data?.feed_line?.[0]?.device_assignments?.reduce((arr, assignment) => {
    return [...arr, ...assignment.device.faults];
  }, []);
  const codes = faults?.reduce((set, f) => {
    set.add(f.code);
    return set;
  }, new Set());
  if (codes?.size > 0) {
    if (codes.size === 1) {
      faultText = FAULT_CODE_LOOKUP[Array.from(codes)[0]]?.name || 'Unknown';
    } else {
      faultText = 'Inactive and Empty Pipe';
    }
  }
  return (
    <>
      {floEvents && (
        <FeedLineConsumptionTile
          lineName={lineName}
          lineId={lineId}
          barnId={barnId}
          usage={Math.round(lineUsage)}
          start={dateRange.from * 1000}
          end={dateRange.to * 1000}
          floEvents={loading ? [] : floEvents}
          view={view}
          faultText={faultText}
        />
      )}
    </>
  );
}

FeedLineConsumptionController.propTypes = {
  lineId: PropTypes.string.isRequired,
  barnId: PropTypes.string.isRequired,
  dateRange: PropTypes.object.isRequired,
  view: PropTypes.string,
};

export default FeedLineConsumptionController;
