import PropTypes from 'prop-types';
import FeedFloButton from '../../atoms/FeedFloButton';
import './UnsavedChangesBar.scss';

function UnsavedChangesBar({ isValid = false, loading = false, onSaveClick = () => {} }) {
  return (
    <div className="UnsavedChangesBar">
      <div className="UnsavedChangesBar-text">Unsaved changes</div>
      {!isValid && <span className="UnsavedChangesBar-errorText">Error found, please fix any issues below</span>}
      <FeedFloButton
        className="UnsavedChangesBar-saveButton"
        type="secondary"
        disabled={!isValid || loading}
        onClick={onSaveClick}
      >
        Save
      </FeedFloButton>
    </div>
  );
}

UnsavedChangesBar.propTypes = {
  isValid: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onSaveClick: PropTypes.func.isRequired,
};

export default UnsavedChangesBar;
