// eslint-disable-entire-file jsx-a11y/click-events-have-key-events
import React from 'react';
import PropTypes from 'prop-types';
import FeedFloToolTip from '../../atoms/FeedFloToolTip';
import { RedXIcon, CopyIcon, KeyIcon } from '../../atoms/Icons';
import './APIInfoTile.scss';

function APIInfoTile({ title = '', permissions = '', date = '', secretToken = '', onDelete = () => {} }) {
  return (
    <>
      {secretToken?.length > 0 && (
        <div className="ApiGeneratedKey">
          <div className="topLine">
            <div className="keyColor">
              <KeyIcon />
            </div>
            <div className="keyText">{secretToken}</div>
            <div
              className="copyTooltip"
              role="button"
              tabIndex={0}
              onClick={() => {
                navigator.clipboard.writeText(secretToken);
              }}
              aria-hidden="true"
            >
              <FeedFloToolTip icon={<CopyIcon />} description="Copy Key" />
            </div>
          </div>
        </div>
      )}
      <div className={`APIInfoTile ${secretToken?.length > 0 && 'changeBorder'}`}>
        <div className="left">
          <div className="topLine">
            <div className="type">{title}</div> <div className="permissions"> - {permissions}</div>
          </div>
          <div className="bottomLine">
            Expires on <div className="date"> {date}</div>
          </div>
        </div>
        <div role="button" tabIndex={0} className="right tooltip" onMouseDown={() => onDelete()}>
          <FeedFloToolTip icon={<RedXIcon />} description="Click To Delete" />
        </div>
      </div>
    </>
  );
}

APIInfoTile.propTypes = {
  title: PropTypes.string.isRequired,
  permissions: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  secretToken: PropTypes.string,
};

export default APIInfoTile;
