/* eslint-disable max-len */
import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery, useMutation } from '@apollo/client';
import WebAppContext from '../../utils/webAppContext';
import Heading from '../../atoms/Heading';
import UnsavedChangesBar from '../../molecules/UnsavedChangesBar';
import UnitInputRow from './UnitInputRow';
import { convertGramsToLargeUnits, convertLargeUnitsToGrams, weightLargeUnitLabel } from '../../utils/unitConversion';
import './BarnSettingsTab.scss';

const GET_BARN_SETTINGS_GQL = gql`
  query BarnSettingsQuery($barnId: uuid!, $type: String, $version: Int) {
    barn_settings(where: { farm_id: { _eq: $barnId }, type: { _eq: $type }, version: { _eq: $version } }) {
      settings
    }
  }
`;

const UPSERT_BARN_SETTINGS_GQL = gql`
  mutation BarnSettingsMutation($objects: [barn_settings_insert_input!]!) {
    insert_barn_settings(
      objects: $objects
      on_conflict: { constraint: barn_settings_type_version_farm_id_unique, update_columns: [settings] }
    ) {
      affected_rows
    }
  }
`;

function BarnSettingsTab({ barnId = '' }) {
  const { isMetric } = useContext(WebAppContext);
  const [initialDeliverySize, setInitialDeliverySize] = useState('');
  const [deliverySize, setDeliverySize] = useState('');
  const [shippingTime, setShippingTime] = useState('');
  const { loading: settingsLoading, data: settingsData } = useQuery(GET_BARN_SETTINGS_GQL, {
    variables: {
      barnId,
      type: 'bin_inventory_management',
      version: 0,
    },
    notifyOnNetworkStatusChange: true,
  });
  const [saveSettings, { loading: savingSettings }] = useMutation(UPSERT_BARN_SETTINGS_GQL);
  const barnSettings = settingsData?.barn_settings?.[0]?.settings || {};
  const {
    typical_feed_delivery_size_in_grams: typicalFeedDeliverySizeInGrams,
    feed_order_shipping_time_in_hours: feedOrderShippingTime,
  } = barnSettings;
  const unsavedChanges = deliverySize !== initialDeliverySize || shippingTime !== feedOrderShippingTime;
  const deliverySizeValid = deliverySize !== '' && Number.parseInt(deliverySize, 10) > 0;
  const shippingTimeValid = shippingTime !== '' && Number.parseInt(shippingTime, 10) > 0;
  const isValid = deliverySizeValid && shippingTimeValid;

  useEffect(() => {
    const largeUnitsDeliverySize =
      typicalFeedDeliverySizeInGrams !== undefined
        ? convertGramsToLargeUnits(isMetric, typicalFeedDeliverySizeInGrams, 0)
        : '';

    setDeliverySize(`${largeUnitsDeliverySize || 24}`);
    setShippingTime(`${feedOrderShippingTime || 48}`);

    // deliverySize is converted to small units locally before being saved, but the UI displays it as large units. A
    // copy of the amount in large units needs to be kept client side in order to check against the entered value to
    // determine when the "unsaved changes" bar should be displayed.
    setInitialDeliverySize(`${largeUnitsDeliverySize}`);
  }, [typicalFeedDeliverySizeInGrams, feedOrderShippingTime]);

  const onChangeDeliverySize = (value) => {
    setDeliverySize(value);
  };

  const onChangeShippingTime = (value) => {
    setShippingTime(value);
  };

  const onSaveClick = () => {
    saveSettings({
      variables: {
        objects: [
          {
            type: 'bin_inventory_management',
            version: 0,
            farm_id: barnId,
            settings: {
              typical_feed_delivery_size_in_grams: convertLargeUnitsToGrams(isMetric, deliverySize),
              feed_order_shipping_time_in_hours: shippingTime,
            },
          },
        ],
      },
      refetchQueries: ['BarnSettingsQuery'],
    });
  };

  const renderBarnSettings = () => {
    if (settingsLoading) {
      return (
        <>
          <UnitInputRow loading={true} />
          <UnitInputRow loading={true} />
        </>
      );
    }

    return (
      <>
        <UnitInputRow
          label="Typical Feed Delivery Size:"
          value={deliverySize}
          onChange={onChangeDeliverySize}
          unit={`${weightLargeUnitLabel(isMetric)}s`}
        />
        <UnitInputRow
          label="Feed Order Shipping Time:"
          value={shippingTime}
          onChange={onChangeShippingTime}
          unit="Hours"
        />
      </>
    );
  };

  return (
    <div className="BarnSettingsTab-tabContent">
      <Heading className="BarnSettingsTab-subheading" text="Bin Inventory Management" underline={true} />
      {!settingsLoading && unsavedChanges && (
        <UnsavedChangesBar isValid={isValid} loading={settingsLoading || savingSettings} onSaveClick={onSaveClick} />
      )}
      <div className="BarnSettingsTab-barnSettings">{renderBarnSettings()}</div>
    </div>
  );
}

BarnSettingsTab.propTypes = {
  barnId: PropTypes.string.isRequired,
};

export default BarnSettingsTab;
