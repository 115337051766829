/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FeedFloButton from '../../atoms/FeedFloButton';
import './DeleteConfirmation.scss';

function DeleteConfirmation({ onDelete, onCancel }) {
  const revokeDescription = `Are you sure you want to revoke this key?`;
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  return (
    <>
      <div className="DeleteConfirmationBlur" />
      <div className="DeleteConfirmation">
        <div className="revokeDescription">{revokeDescription}</div>
        <div className="buttons">
          <FeedFloButton
            onClick={() => {
              setButtonsDisabled(true);
              onCancel();
            }}
            disabled={buttonsDisabled}
          >
            Cancel
          </FeedFloButton>
          <div className="red">
            <FeedFloButton
              onClick={() => {
                setButtonsDisabled(true);
                onDelete();
              }}
              disabled={buttonsDisabled}
            >
              Revoke Key
            </FeedFloButton>
          </div>
        </div>
      </div>
    </>
  );
}

DeleteConfirmation.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeleteConfirmation;
