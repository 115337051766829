import { useState } from 'react';
import PropTypes from 'prop-types';
import useDefaultDateRange from '../../utils/hooks/useDefaultDateRange';
import FeedFloToggleButton from '../../atoms/FeedFloToggleButton';
import FeedFloStatTile from '../../molecules/FeedFloStatTile';
import BarnConsumptionController from '../../molecules/BarnConsumptionTile';
import DateRangePicker from '../../organisms/DateRangePicker';
import { InfoIcon } from '../../atoms/Icons';
import FeedLineConsumptionController from '../../molecules/FeedLineConsumptionTile';
import Heading from '../../atoms/Heading';

import './BarnConsumptionTab.scss';

function ConsumptionTab({ barn = {}, barnId = '' }) {
  const defaultDateRange = useDefaultDateRange(barnId);
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [view, setView] = useState('cumulative');
  const [dateRangePickerVisible, setDateRangePickerVisible] = useState(false);
  const feedLineData = barn.feed_lines;
  const onToggleChange = (e) => {
    setView(e.toggle ? 'cumulative' : 'daily');
  };

  return (
    <div className="BarnConsumptionTab">
      <div className="topHalf">
        <div className="topLeftSide">
          <div className="headers">
            <DateRangePicker
              visible={dateRangePickerVisible}
              dateRange={dateRange}
              setVisible={setDateRangePickerVisible}
              setDateRange={setDateRange}
            />
            <FeedFloToggleButton onChange={onToggleChange} text={['Cumulative', 'Daily']} />
          </div>
          <div className="BarnConsumptionHolder">
            <BarnConsumptionController
              barnId={barnId}
              dateRange={{ from: dateRange.from.unix(), to: dateRange.to.unix() }}
              view={view}
            />
          </div>
        </div>
        <div className="topRightSide">
          <div className="statHeader">Current Group Stats</div>
          <div className="tiles">
            <FeedFloStatTile
              title="Progress to Market Readiness"
              value="N/A"
              subtitle="Based on consumption to growth curve"
              icon={<InfoIcon />}
            />
            <FeedFloStatTile title="No. Of Animals" value="N/A" subtitle="Updated on 8 Aug" icon={<InfoIcon />} />
            <FeedFloStatTile title="Week" value="N/A" subtitle="Updated on 8 Aug" icon={<InfoIcon />} />
          </div>
        </div>
      </div>
      <Heading text="Feed Lines" />
      <div className="bottomHalf">
        {feedLineData?.map((lineId) => {
          return (
            <div key={lineId.id} className="FeedLineConsumptionHolders">
              <FeedLineConsumptionController
                lineId={lineId.id}
                barnId={barnId}
                dateRange={{ from: dateRange.from.unix(), to: dateRange.to.unix() }}
                view={view}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

ConsumptionTab.propTypes = {
  barn: PropTypes.object.isRequired,
  barnId: PropTypes.string.isRequired,
};

export default ConsumptionTab;
