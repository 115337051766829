/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import FeedFloButton from '../../atoms/FeedFloButton';
import './react-datepicker.scss';
import './FeedFloDatePicker.scss';

const DATE_FORMAT = 'YYYY-MM-DD';

/**
 *
 * Props:
 * [from]             - will enforce time to the start of the day, a.k.a [00:00:00]
 *                    - type: Date object
 *                    - default: beginning of current day
 *
 * [to]               - will enforce time to the end of the day, a.k.a [23:59:999]
 *                    - type: Date object
 *                    - default: end of current day
 *
 * [onChange]         - callback function when the dateRange is changed
 * [onClickedOutside] - callback function to notify the caller that the user clicked outside of the component
 *
 */
function FeedFloDatePicker({
  to = moment().toDate(),
  from = moment().toDate(),
  onChange = () => {},
  onClickedOutside = () => {},
}) {
  const [startDate, setStartDate] = useState(new Date(from.setHours(0, 0, 0, 0)));
  const [endDate, setEndDate] = useState(new Date(to.setHours(23, 59, 59, 999)));

  // the string inputed in the text box in the header of date picker
  const [startText, setStartText] = useState('');
  const [endText, setEndText] = useState('');
  const fromPlaceHolder = moment(from).format(DATE_FORMAT);
  const toPlaceHolder = moment(to).format(DATE_FORMAT);

  /**
   * For detection if outside is clicked
   */
  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClickedOutside();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  /**
   * input from the Calendar
   * uses date-picker library
   */
  function onDateSelectedChange(dates) {
    const [start, end] = dates;

    setStartDate(new Date(start.setHours(0, 0, 0, 0)));
    setStartText(moment(start).format(DATE_FORMAT));

    if (!end) {
      // restart range
      setEndDate(null);
      setEndText('');
    } else {
      setEndDate(new Date(end.setHours(23, 59, 59, 999)));
      setEndText(moment(end).format(DATE_FORMAT));
    }
  }

  /**
   * input from the text input boxes
   */
  function validateDateFormat(date) {
    return moment(date, DATE_FORMAT, true).isValid();
  }

  function onStartingInputChange(e) {
    setStartText(e.target.value);
    const validDate = validateDateFormat(e.target.value);
    if (validDate) {
      const newStartDate = new Date(e.target.value);
      const newStartDateUTC = new Date(newStartDate.getTime() + newStartDate.getTimezoneOffset() * 60000);
      setStartDate(newStartDateUTC);
    }
  }

  function onEndingInputChange(e) {
    setEndText(e.target.value);
    const validDate = validateDateFormat(e.target.value);
    if (validDate) {
      const newEndDate = new Date(e.target.value);
      const newEndDateUTC = new Date(newEndDate.getTime() + newEndDate.getTimezoneOffset() * 60000);
      setEndDate(newEndDateUTC);
    }
  }

  // Finalize the Date Range and sends the date up to the parent using callback function
  function onApplyClick(e) {
    // enable slection for single day range
    if (endDate === null) {
      const endOfStartDate = new Date(new Date(startDate).setHours(23, 59, 59, 999));
      onChange({ ...e, dateRange: { from: startDate, to: endOfStartDate } });
    } else {
      onChange({ ...e, dateRange: { from: startDate, to: endDate } });
    }
  }

  function onCancelClick(e) {
    onChange(e);
  }

  const header = (
    <div className="header">
      <div>
        <div>Starting</div>
        <input
          onChange={(e) => {
            onStartingInputChange(e);
          }}
          value={startText}
          type="text"
          name="starting"
          placeholder={fromPlaceHolder}
          autoComplete="off"
        />
      </div>
      <div>
        <div>Ending</div>
        <input
          onChange={(e) => onEndingInputChange(e)}
          value={endText}
          type="text"
          name="ending"
          placeholder={toPlaceHolder}
          autoComplete="off"
        />
      </div>
    </div>
  );
  const footer = (
    <div className="footer">
      <FeedFloButton onClick={(e) => onCancelClick(e)}>Cancel</FeedFloButton>
      <FeedFloButton
        onClick={(e) => {
          onApplyClick(e);
        }}
      >
        Apply
      </FeedFloButton>
    </div>
  );

  return (
    <div className="FeedFloDatePicker" ref={wrapperRef}>
      {header}
      <DatePicker
        className="Calendar"
        onChange={(dates) => onDateSelectedChange(dates)}
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()} // to disable selection of future dates
        selectsRange
        inline
        disabledKeyboardNavigation
      />
      {footer}
    </div>
  );
}

FeedFloDatePicker.propTypes = {
  from: PropTypes.object,
  to: PropTypes.object,
  onChange: PropTypes.func,
  onClickedOutside: PropTypes.func,
};

export default FeedFloDatePicker;
