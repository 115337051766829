import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './RadioButton.scss';

function RadioButton({ defaultChecked = false, disabled = false, onChange = () => {} }) {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const onCheck = (e) => {
    setIsChecked(e.target.checked);
    onChange(e.target.checked);
  };

  return (
    <input
      className={`RadioButton ${disabled ? 'grey' : ''}`}
      type="radio"
      checked={isChecked}
      onChange={(e) => onCheck(e)}
      disabled={disabled}
    />
  );
}

RadioButton.propTypes = {
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default RadioButton;
