/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { EyeIcon } from '../../atoms/Icons';
import './FeedFloWatchListRow.scss';

function FeedFloWatchListRow({ label = '', onDelete = () => {} }) {
  return (
    <div className="FeedFloWatchListRow">
      <div className="input-field">
        {label.length > 0 ? label : <div />}
        <div>
          <div className="right tooltip">
            <div onClick={onDelete} role="button" tabIndex={0}>
              <EyeIcon />
            </div>
            <span className="tooltiptext">Remove</span>
          </div>
        </div>
      </div>
    </div>
  );
}

FeedFloWatchListRow.propTypes = {
  label: PropTypes.string,
  onDelete: PropTypes.func,
};

export default FeedFloWatchListRow;
