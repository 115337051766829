/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import momentTZ from 'moment-timezone';
import ExportController from './ExportController';
import Heading from '../../atoms/Heading';

import './ExportPage.scss';

export default function ExportPage() {
  const timeZoneString = momentTZ().tz(momentTZ.tz.guess()).format('Z z');

  return (
    <div className="ExportPage">
      <Heading text="Exports" />
      <ExportController />
      <div className="TimeZoneMessage">Data Selected and Exported in {timeZoneString}</div>
    </div>
  );
}
