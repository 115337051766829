import React, { useEffect, useState, forwardRef, useImperativeHandle, useContext } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { gql, useQuery, useMutation } from '@apollo/client';
import WebAppContext from '../../../utils/webAppContext';
import GeneralSettingsView from './GeneralSettings';

const USER_PREF_GQL = gql`
  query UserPreferenceQuery {
    user_preference(where: { version: { _eq: 0 } }) {
      preferences
    }
  }
`;

const UPDATE_USER_PREF_GQL = gql`
  mutation MutateUserPreference(
    $userID: String
    $metric: bool
    $firstName: String
    $lastName: String
    $phoneNumber: String
  ) {
    insert_user_preference_one(
      object: {
        version: "0"
        user_id: $userID
        preferences: { metric: $metric, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber }
      }
      on_conflict: { constraint: user_preference_user_id_version_unique, update_columns: preferences }
    ) {
      preferences
    }
  }
`;

function cleanUpPhoneNumbers(phoneNumber) {
  return phoneNumber.replace(/[()\s-]/g, '');
}

const GeneralSettingsController = forwardRef(({ onChange }, ref) => {
  const [userPref, setUserPref] = useState({});
  const { setIsMetric } = useContext(WebAppContext);
  const { user, isAuthenticated } = useAuth0();

  const { loading: getLoading, data } = useQuery(USER_PREF_GQL, { fetchPolicy: 'no-cache' });
  const [updateUserPref, { loading: updateLoading }] = useMutation(UPDATE_USER_PREF_GQL);

  useEffect(() => {
    const pref = data?.user_preference[0]?.preferences; // from hasura
    const generalData = (prev) => ({
      ...prev,
      firstName: pref?.firstName,
      lastName: pref?.lastName,
      phoneNumber: pref?.phoneNumber,
      metric: pref?.metric,
    });

    setUserPref(generalData);
  }, [data]);

  async function mutateUserPreference() {
    userPref.phoneNumber = cleanUpPhoneNumbers(userPref.phoneNumber);
    updateUserPref({
      variables: {
        userID: user.sub,
        metric: userPref.metric,
        firstName: userPref.firstName,
        lastName: userPref.lastName,
        phoneNumber: userPref.phoneNumber,
      },
    });
  }

  function onFormChange(newData) {
    setUserPref(newData);
    onChange(true);
  }

  useImperativeHandle(ref, () => ({
    save: async () => {
      await mutateUserPreference();
      setIsMetric(userPref.metric);
    },
  }));

  useEffect(() => {
    if (isAuthenticated) {
      const generalData = (prev) => ({
        ...prev,
        email: user.email, // from auth0
      });

      setUserPref(generalData);
    }
  }, [user]);

  return (
    <GeneralSettingsView
      loading={getLoading || updateLoading}
      data={userPref}
      onChange={(newData) => onFormChange(newData)}
    />
  );
});
GeneralSettingsController.displayName = 'GeneralSettingsController';
GeneralSettingsController.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default GeneralSettingsController;
