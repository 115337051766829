import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import FeedFloTable from '../../organisms/FeedFloTable';
import StatusBadge from '../../atoms/StatusBadge';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { FAULT_CODE_LOOKUP } from '../../utils/utils';
import useFaultCodeFilter from '../../utils/useFaultCodeFilter';

const BARN_GQL = gql`
  query BarnListQuery(
    $max_rows: Int
    $row_offset: Int
    $codeFilters: [fault_bool_exp!]!
    $fault_code_older_than: bigint
  ) {
    farm_aggregate {
      aggregate {
        count
      }
    }
    farm(limit: $max_rows, offset: $row_offset, order_by: { name: asc }) {
      name
      id
      latitude
      longitude
      address
      feed_lines {
        name
        device_assignments(
          where: { status: { _eq: "active" }, deleted_at: { _is_null: true }, ended_at: { _is_null: true } }
        ) {
          device {
            faults(where: { started_at: { _is_null: false }, ended_at: { _is_null: true }, _or: $codeFilters }) {
              code
              started_at
              ended_at
            }
          }
        }
      }
    }
  }
`;

function BarnListController() {
  const codeFilters = useFaultCodeFilter();
  const [pageNum, setPageNum] = useState(0);
  const { width } = useWindowDimensions();
  const MAX_ROWS_TO_DISPLAY = 20;
  const d = new Date();
  d.setHours(d.getHours() - 1);
  // convert to unix and round to the minute
  const unixHourBefore = Math.trunc(Math.floor(d.getTime() / 1000) / 60) * 60;

  const { loading, data, error } = useQuery(BARN_GQL, {
    variables: {
      max_rows: MAX_ROWS_TO_DISPLAY,
      row_offset: pageNum * MAX_ROWS_TO_DISPLAY,
      codeFilters,
      fault_code_older_than: unixHourBefore,
    },
  });

  if (loading) return <></>;
  if (error) return <>{JSON.stringify(error)}</>;
  const barns = loading ? [] : data?.farm;
  const barnCount = loading ? 0 : data?.farm_aggregate?.aggregate.count;

  const tableHeaders = [
    { t: 'NAME', s: 'l' },
    { t: 'LOCATION', s: 'l' },
    { t: '', s: 'g' },
    width > 1000 ? { t: `STATUS`, s: 'm' } : { t: '', s: 's' },
  ];

  function getTableRows() {
    if (loading) return [];
    const spacer = '';
    const rows = barns?.map((barn) => {
      const { id, name, address } = barn;
      const titleLink = <Link to={`/b/${id}/consumption`}>{name}</Link>;

      let faultText = null;
      const faults = barn?.feed_lines?.reduce((arr, line) => {
        const faults = line?.device_assignments?.reduce((arr, assignment) => {
          return [...arr, ...assignment.device.faults];
        }, []);
        return [...arr, ...faults];
      }, []);
      const codes = faults?.reduce((set, f) => {
        set.add(f.code);
        return set;
      }, new Set());
      if (codes?.size > 0) {
        if (codes.size === 1) {
          faultText = FAULT_CODE_LOOKUP[Array.from(codes)[0]]?.name || 'Unknown';
        } else {
          faultText = 'Inactive and Empty Pipes';
        }
      }

      const status = faultText ? (
        <StatusBadge status="error" text={faultText} />
      ) : (
        <StatusBadge status="success" text="Ready" />
      );
      return [titleLink, address, spacer, status];
    });
    return rows;
  }

  return (
    <FeedFloTable
      headers={tableHeaders}
      rows={getTableRows()}
      itemTotalCount={barnCount}
      loading={loading}
      pageNum={pageNum}
      onClickNext={() => {
        setPageNum(pageNum + 1);
      }}
      onClickPrevious={() => {
        setPageNum(pageNum - 1);
      }}
      emptyImage="/images/empty-table.svg"
    />
  );
}

export default BarnListController;
