import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { UserIcon, HelpIcon, MenuIcon, EmailIcon, PhoneIcon } from '../../atoms/Icons';
import UserDropDown from '../../molecules/UserDropDown';
import './TopBar.scss';

function TopBar({ onMenuButton = () => {} }) {
  const { logout } = useAuth0();
  const [isMenuShown, setMenuShown] = useState(false);
  const [isHelpShown, setHelpShown] = useState(false);
  const history = useHistory();

  const helpRef = useRef(null);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setHelpShown(false);
    setMenuShown(!isMenuShown);
  };
  const toggleHelp = () => {
    setMenuShown(false);
    setHelpShown(!isHelpShown);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuShown(false);
    }
    if (helpRef.current && !helpRef.current.contains(event.target)) {
      setHelpShown(false);
    }
  };

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  return (
    <>
      <div className="TopBar">
        <button
          className="buttons left menu"
          type="button"
          onClick={() => {
            onMenuButton();
          }}
        >
          <div ref={helpRef}>
            <MenuIcon />
          </div>
        </button>

        <button
          className="buttons right"
          type="button"
          onClick={() => {
            toggleMenu();
          }}
        >
          <div ref={menuRef}>
            <UserIcon />
          </div>
        </button>

        <button
          className="buttons right"
          type="button"
          onClick={() => {
            toggleHelp();
          }}
        >
          <div ref={helpRef}>
            <HelpIcon />
          </div>
        </button>
      </div>
      {isMenuShown && (
        <UserDropDown
          items={[
            {
              title: 'Settings',
              onClick: () => {
                history.push('/settings');
              },
            },
            {
              title: 'Log Out',
              onClick: () => {
                logout({ returnTo: window.location.origin });
              },
            },
          ]}
        />
      )}
      {isHelpShown && (
        <UserDropDown
          items={[
            {
              icon: <EmailIcon />,
              title: 'Email: support@feedflo.com',
              onClick: () => {
                window.location = 'mailto:support@feedflo.com';
              },
            },
            {
              icon: <PhoneIcon />,
              title: 'Phone: +1 (204) 944-7395',
              onClick: () => {
                window.location = 'tel:+1 (204) 944-7395';
              },
            },
          ]}
        />
      )}
    </>
  );
}

TopBar.propTypes = {
  onMenuButton: PropTypes.func.isRequired,
};

export default TopBar;
