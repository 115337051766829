import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { max, bisector } from 'd3-array';
import { timeFormat } from 'd3-time-format';
import { ParentSize } from '@visx/responsive';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { GridColumns } from '@visx/grid';
import { LinePath } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleTime, scaleLinear } from '@visx/scale';
import { MarkerCircle } from '@visx/marker';
import { Tooltip, useTooltip, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';

import WebAppContext from '../../utils/webAppContext';
import { weightSmallUnitLabel } from '../../utils/unitConversion';
import { addComma } from '../../utils/utils';
import './FeedFloChart.scss';

// accessors
const getX = (d) => d.t;
const getY = (d) => d.v;

export const backgroundColor = '#da7cff';
export const labelColor = '#340098';

const MS_PER_DAY = 24 * 60 * 60 * 1000;

function tickFormatFactory(start, end) {
  const daysInRange = (end - start) / MS_PER_DAY;

  // MultiDay charts only have 1 tick per day
  if (daysInRange > 1) {
    return (v) => timeFormat('%b %d')(v);
  }

  // Single Day Charts show the Date on the edges and the time on the ticks.
  return (v, index, ticks) => {
    if (index === 0 || index === ticks.length - 1) {
      return timeFormat('%b %d')(v); // abbreviated month name + Day of Month ex:Jan 15
    }
    return timeFormat('%I%p')(v); // Hour & AM/PM ex: 12am
  };
}
function calculateXAxisNumTicks(start, end) {
  const daysInRange = (end - start) / MS_PER_DAY;

  if (daysInRange > 20) {
    return Math.floor(daysInRange / 7);
  }
  if (daysInRange > 1) {
    return daysInRange;
  }

  return 4;
}

function trimFloEvent(e, start, end) {
  if (e.s < start && e.e > start) {
    // Overlaps over start
    //     start |---------... end
    // e.s |---------... e.e
    e.s = start;
    e.v *= (e.e - start) / (e.e - e.s); // Finds Value for the remaining time.
  }
  if (e.e > end && e.s < end) {
    // start ...---------| end
    //        e.s ...---------| e.e
    e.e = end;
    e.v *= (end - e.s) / (e.e - e.s); // Finds Value for the remaining time.
  }

  return e;
}

function getEventRate(e) {
  return e.v / (e.e - e.s);
}
function breakDownPair(e1, e2) {
  let output = [];

  if (e1.e < e2.s) {
    return [e1, e2];
  }

  if (e1.s === e2.s && e1.e === e2.e) {
    return [
      {
        e: e1.e,
        s: e1.s,
        v: e1.v + e2.v,
      },
    ];
  }

  if (e1.s < e2.s) {
    output.push({ s: e1.s, e: e2.s, v: getEventRate(e1) * (e2.s - e1.s) });
  }

  if (e1.e < e2.e) {
    //  Overlap
    output.push({ s: e2.s, e: e1.e, v: (getEventRate(e1) + getEventRate(e2)) * (e1.e - e2.s) });
    output.push({ s: e1.e, e: e2.e, v: getEventRate(e2) * (e2.e - e1.e) });
  } else {
    //  Inside
    output.push({ s: e2.s, e: e2.e, v: (getEventRate(e1) + getEventRate(e2)) * (e2.e - e2.s) });
    output.push({ s: e2.e, e: e1.e, v: getEventRate(e1) * (e1.e - e2.e) });
  }
  output = output.filter((e) => typeof e.v === 'number' && e.v > 0 && e.s < e.e);

  const eventReducer = (sum, e) => sum + e.v;
  const ogSum = [e1, e2].reduce(eventReducer, 0);
  const newSum = output.reduce(eventReducer, 0);

  if (Math.abs(ogSum - newSum) > 1) {
    console.log(`Error Introduced in Event Simplification (og:${ogSum} new:${newSum})`);
  }

  return output;
}

function eventOverlapRemover(floEvents) {
  let i = 0;
  while (i < floEvents.length - 1) {
    const e1 = floEvents[i];
    const e2 = floEvents[i + 1];

    if (e1.e > e2.s) {
      const splitEvents = breakDownPair(e1, e2);
      floEvents.splice(i, 2, ...splitEvents);
      floEvents = floEvents.sort((e1, e2) => e1.s - e2.s);

      i = 0;
    } else {
      i += 1;
    }
  }
  return floEvents;
}

// This function translates a list of events into line point data.
// Each event gets a start and end time so that the slope of the line matches the real start and end times.
function eventsToLinePoints(floEvents, start, end) {
  if (floEvents.length === 0) {
    return { lineData: [], total: 0 };
  }

  floEvents = eventOverlapRemover(floEvents);
  let total = 0;

  const lineData = floEvents.reduce((acc, e) => {
    e = trimFloEvent(e, start, end);

    if (e.s < start && e.e === start) {
      total += e.v;
      acc.push({ t: e.s, v: total });
    } else {
      acc.push({ t: e.s, v: total });
      total += e.v;
      acc.push({ t: e.e, v: total });
    }

    return acc;
  }, []);

  return { lineData, total };
}

// Iterate through old list of events [floEvents] and creates a new list of events [chartEvents] and returns it.
// In chartEvents, each events consists of the sum of the Value that occur each day.
function convertToDailyEvents(floEvents, start, end) {
  const daysInRange = Math.round((end - start) / MS_PER_DAY);

  let floEventsIndex = 0;

  let currChartDate = new Date(start).setHours(0, 0, 0, 0); // set to beginning of the day in unix millisec
  let nextChartDate = currChartDate + 1 * MS_PER_DAY; // the next day of currChartDate

  let prevTotalValue = 0;
  let currTotalValue = 0;

  let chartEvents;

  if (daysInRange > 1) {
    chartEvents = Array(daysInRange)
      .fill(0)
      .map(() => {
        currTotalValue = 0;

        // sums all the values occured in a single day
        while (floEventsIndex < floEvents.length && floEvents[floEventsIndex].s < nextChartDate) {
          currTotalValue += floEvents[floEventsIndex].v;
          floEventsIndex += 1;
        }

        const event = {
          s: currChartDate - 1 * MS_PER_DAY,
          e: currChartDate,
          // we want to subtract the previous value because the chart will add the previous value when plotting.
          v: currTotalValue - prevTotalValue,
        };

        prevTotalValue = currTotalValue;
        currChartDate = nextChartDate;
        nextChartDate = currChartDate + 1 * MS_PER_DAY;
        return event;
      });
  } else {
    // single day selected for date range
    let total = 0;
    for (let i = 0; i < floEvents.length; i += 1) {
      total += floEvents[i].v;
    }
    chartEvents = [
      { s: currChartDate - 1 * MS_PER_DAY, e: currChartDate, v: total },
      { s: currChartDate, e: currChartDate + 1 * MS_PER_DAY, v: 0 },
    ];
  }

  return chartEvents;
}

function FeedFloChart({ floEvents = [], cumulative = false, start = 0, end = 0 }) {
  const { isMetric } = useContext(WebAppContext);
  // Chart LAYOUT VARIABLES
  const CHART_MARGIN = { top: 10, right: 25, bottom: 25, left: 40 };
  const xAxisNumTicks = calculateXAxisNumTicks(start, end);
  const xAxisTickFormat = tickFormatFactory(start, end);

  const chartLineData = useMemo(() => {
    const sortedEvents = floEvents.sort((e1, e2) => e1.s - e2.s);
    let cleanedUpEvents = sortedEvents.filter((e) => typeof e.v === 'number' && e.v > 0 && e.s < e.e);

    if (!cumulative && floEvents.length > 0) {
      cleanedUpEvents = convertToDailyEvents(cleanedUpEvents, start, end);
    }

    let { lineData } = eventsToLinePoints(cleanedUpEvents, start, end);

    // clean up of lineData
    if (cumulative) {
      lineData.unshift({ t: start, v: 0 });
    } else {
      lineData.shift();

      // this removes duplicated items in the array of objects
      lineData = lineData.filter(
        (value, index, self) => index === self.findIndex((item) => item.t === value.t && item.v === value.v),
      );
    }
    return lineData;
  }, [floEvents, cumulative]);

  return (
    <div className="FeedFloChart">
      <ParentSize debounceTime={10}>
        {({ width, height }) => {
          const xMax = width - CHART_MARGIN.left - CHART_MARGIN.right;
          const yMax = height - CHART_MARGIN.top - CHART_MARGIN.bottom;

          const xScale = scaleTime({
            domain: [start, end],
          });
          const yScale = scaleLinear({
            domain: [0, max(chartLineData, getY)],
          });

          xScale.range([0, xMax]);
          yScale.range([yMax, 0]);

          const { tooltipData, tooltipLeft = 0, tooltipTop = 0, tooltipOpen, showTooltip, hideTooltip } = useTooltip();

          // More information about d3-array bisector:
          // https://stackoverflow.com/questions/26882631/d3-what-is-a-bisector
          const bisectDate = bisector((d) => new Date(d.t)).right;
          const getDate = (d) => (d.t ? new Date(d.t) : null);

          // Gets the nearest available data based on x
          const getDataFromX = (x) => {
            const date = xScale.invert(x);
            const index = bisectDate(chartLineData, date);
            const d0 = chartLineData[index - 1];
            const d1 = chartLineData[index];
            let d = d0;

            if (!(d0 && d1)) {
              return undefined;
            }

            if (d1 && getDate(d1)) {
              // if the hovered date is closer to the latter date (d1), choose the latter
              d = date.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - date.valueOf() ? d1 : d0;
            }

            return d;
          };

          const formatDateTooltip = (hoveredDate, start) => {
            let startDate = '';

            if (start) startDate = `${start.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`;
            const hoveredDateStr = hoveredDate.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
            });

            const hoveredTimeStr = hoveredDate.toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
            });

            let formattedDate = null;
            if (cumulative) formattedDate = `${startDate} - ${hoveredDateStr} ${hoveredTimeStr}`;
            else formattedDate = `${startDate}`;

            return formattedDate;
          };

          const handleTooltip = (event) => {
            if (floEvents?.length > 0) {
              // gets x, y axis
              const point = localPoint(event);
              const coords = { x: point.x - CHART_MARGIN.left }; // add an offset

              // gets lineData item {t: <time>, v: <value> } based on x coordinate
              const data = getDataFromX(coords.x);

              // do not show tooltip if there is no data
              if (data) {
                const currXValue = cumulative ? new Date(xScale.invert(coords.x)) : getDate(data);

                const currDay = new Date(currXValue.getTime());

                const formattedDate = formatDateTooltip(
                  currXValue,
                  // cumulative = start date
                  // daily = a day before
                  cumulative ? new Date(start) : currDay,
                );

                showTooltip({
                  tooltipLeft: cumulative ? coords.x : xScale(getX(data)),
                  tooltipTop: yScale(getY(data)),
                  tooltipData: { mass: data.v, displayedDate: formattedDate },
                });
              }
            }
          };

          return (
            <>
              <svg
                width={width}
                height={height}
                onTouchStart={handleTooltip}
                onTouchMove={handleTooltip}
                onMouseMove={handleTooltip}
                onMouseLeave={() => hideTooltip()}
              >
                <MarkerCircle id="marker-circle" fill="#27AE60" size={2} refX={2} />
                <Group left={CHART_MARGIN.left} top={CHART_MARGIN.top}>
                  <GridColumns scale={xScale} width={xMax} height={yMax} stroke="#D9DCE1" numTicks={xAxisNumTicks} />
                  <AxisBottom
                    top={yMax}
                    scale={xScale}
                    numTicks={xAxisNumTicks}
                    hideTicks
                    stroke="#D9DCE1"
                    tickFormat={xAxisTickFormat}
                  />
                  <AxisLeft scale={yScale} hideTicks stroke="#D9DCE1" numTicks={5} />
                  <LinePath
                    data={chartLineData}
                    x={(d) => xScale(getX(d)) ?? 0}
                    y={(d) => yScale(getY(d)) ?? 0}
                    stroke="#27AE60"
                    strokeWidth={2}
                    strokeOpacity={1}
                    markerStart={cumulative ? '' : 'url(#marker-circle)'}
                    markerMid={cumulative ? '' : 'url(#marker-circle)'}
                    markerEnd={cumulative ? '' : 'url(#marker-circle)'}
                  />
                  {tooltipOpen && (
                    <g pointerEvents="none">
                      <line
                        stroke="#000"
                        strokeWidth="1"
                        x1={tooltipLeft}
                        x2={tooltipLeft}
                        y1={0}
                        opacity={0.4}
                        y2={height}
                        strokeDasharray={(5, 5)}
                      />
                      <circle cx={tooltipLeft} cy={tooltipTop} r="4" fill="#27AE60" />
                    </g>
                  )}
                </Group>
              </svg>
              {tooltipOpen && (
                <div>
                  <Tooltip
                    key={Math.random()}
                    top={0}
                    left={tooltipLeft}
                    style={{
                      ...defaultStyles,
                      borderRadius: '4px',
                      backgroundColor: '#fff',
                      color: '#3C4257',
                      border: '1px solid #A3ACB9',
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    <div className="tooltipInfo">
                      <div className="tooltipMass">
                        <strong>
                          {addComma(Math.round(tooltipData.mass))} {weightSmallUnitLabel(isMetric)}
                        </strong>
                      </div>
                      <div className="tooltipDate">{tooltipData.displayedDate}</div>
                    </div>
                  </Tooltip>
                </div>
              )}
            </>
          );
        }}
      </ParentSize>
    </div>
  );
}

FeedFloChart.propTypes = {
  floEvents: PropTypes.array.isRequired,
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  cumulative: PropTypes.bool,
};

export default FeedFloChart;
